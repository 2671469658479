import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalScreen from "./ModalScreen";
import FirebaseToast from "../../components/firebase/FirebaseToast";
import { showScreenModal, setScreen } from "../../store/actions";
import html2canvas from "html2canvas";

const Screen = ({ children, callback }) => {
  const ref = useRef(null);
  const [MyImg, setMyImg] = useState("");
  const [quid, setQuid] = useState("");

  const { screenValue } = useSelector((state) => state.userAppReducer);
  const { screenModalValue } = useSelector((state) => state.userAppReducer);
  const dispatch = useDispatch();

  const captureScreen = async () => {
    try {
      if (ref.current) {
        html2canvas(ref.current).then((canvas) => {
          // Convert canvas to base64 encoded image
          const imageUrl = canvas.toDataURL();

          // Set the captured image state
          setMyImg(imageUrl);
        });
      }
      // const imageData = image.split(",")[1];
      //  
      // setMyImg("data:image/jpeg;base64," + imageData);
    } catch (error) {
      console.error("Error capturing or sending screenshot:", error);
    } finally {
      dispatch(setScreen(false));
      dispatch(showScreenModal(true));
    }
  };

  useEffect(() => {
    //  
    //  
    if (screenValue) {
      captureScreen();
    }
  }, [screenValue]);

  const { questionsList, currentQues } = useSelector(
    (state) => state.userAppReducer
  );

  useEffect(() => {
      
      
      
      
      
      
      
      
      
     
     

     
    if (questionsList.id) {
       
      setQuid(questionsList.id);
    } else if (questionsList[currentQues].question === undefined) {
       
      setQuid(questionsList[currentQues].id);
    } else {
      setQuid(questionsList[currentQues].question.id);
    }
  }, [screenModalValue]);

  return (
    <div
      className={`w-full justify-center h-screen p-0 bg-gray-100 flex flex-col font-[Almarai] md:max-w-[875px] md:m-auto `}
    >
      {/* <FirebaseToast/> */}

      <div ref={ref}>
        {children}
        <ModalScreen MyImg={MyImg} quid={quid} />
      </div>
    </div>
  );
};

export default Screen;
