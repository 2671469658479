import React, { useState, useEffect, useRef } from "react";
import CanvasDraw from "react-canvas-draw";
import { BackNavBar } from "../../components";
import { setScreen, setShowTool, showScreenModal } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";

function Canvas({ children, language }) {
  const [Brushcolor, setBrush] = useState("red");
  const [brush, setThick] = useState(1);
  const { screenValue } = useSelector((state) => state.userAppReducer);

  const { screenModalValue } = useSelector((state) => state.userAppReducer);

  const [show, setShow] = useState();
  const canvasRef = useRef(null);
  const dispatch = useDispatch();
  const handleClear = () => {
    canvasRef.current.clear();
  };

  const handleUndo = () => {
    canvasRef.current.undo();
  };

  const saveDrawing = () => {
    dispatch(setScreen(true));
  };

  const [width, setWidth] = useState(
    window.innerWidth >= 875 ? 875 : window.innerWidth
  );
  const [height, setHeight] = useState(window.innerHeight);

  const updateDimensions = () => {
    setWidth(window.innerWidth >= 875 ? 875 : window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window?.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  const { setShowToolValue } = useSelector((state) => state.userAppReducer);

  const ShowBns = () => {
     
    setShow(!show);
    dispatch(setShowTool(!setShowToolValue));

    // dispatch(showScreenModal(!screenModalValue));
  };

  // useEffect(() => {
  //    
  // }, [screenModalValue]);

  return (
    <div
      style={{
        backgroundColor: "transparent",
      }}
      className={`w-full h-screen p-0 bg-gray-100 flex flex-col font-[Almarai] md:max-w-[875px] md:m-auto `}
    >
      <BackNavBar
canBack={true}        btns={
          <div className="flex flex-row  gap-2 w-full">
            {setShowToolValue ? (
              <>
                <button
                  className="text-white border   rounded"
                  onClick={handleClear}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                    />
                  </svg>
                </button>
                <button
                  className="text-white border   rounded"
                  onClick={handleUndo}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
                    />
                  </svg>
                </button>

                <button
                  className="text-white border   rounded"
                  onClick={saveDrawing}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z"
                    />
                  </svg>
                </button>
              </>
            ) : null}

            <button className="text-white border   rounded" onClick={ShowBns}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.53 16.122a3 3 0 0 0-5.78 1.128 2.25 2.25 0 0 1-2.4 2.245 4.5 4.5 0 0 0 8.4-2.245c0-.399-.078-.78-.22-1.128Zm0 0a15.998 15.998 0 0 0 3.388-1.62m-5.043-.025a15.994 15.994 0 0 1 1.622-3.395m3.42 3.42a15.995 15.995 0 0 0 4.764-4.648l3.876-5.814a1.151 1.151 0 0 0-1.597-1.597L14.146 6.32a15.996 15.996 0 0 0-4.649 4.763m3.42 3.42a6.776 6.776 0 0 0-3.42-3.42"
                />
              </svg>
            </button>
          </div>
        }
        language={language}
      />

      <div
        style={{
          position: "relative",
          backgroundColor: "transparent",
        }}
      >
        <div className="w-full top-0 absolute m-auto">
          {setShowToolValue && (
            <CanvasDraw
              style={{
                zIndex: 50,
                backgroundColor: "transparent",
                position: "absolute",
              }}
              ref={canvasRef}
              hideGrid={true}
              canvasWidth={width}
              canvasHeight={height}
              brushRadius={brush}
              brushColor={Brushcolor}
              catenaryColor="green"
              lazyRadius={1}
              //   saveDrawing
            />
          )}

          {children}
        </div>
      </div>
    </div>
  );
}

export default Canvas;
