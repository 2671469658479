import axios from "axios";
import React, { useState } from "react";
import ReviewModal from "./ReviewModal";

export default function ReviewRow({ quData ,imgae ,idOfQuestion}) {
   console.log(idOfQuestion||quData.id||quData.issues[0].id);
  console.log(imgae||quData.imgae||quData.issues[0].imgae  );
  const [loading, setloading] = useState(false);

  function sendItsDone(id) {
    setloading(true);
    const token = localStorage.getItem("token");

    const headers = {
      "access-token": "qwertasdfg123@+1",

      Authorization: `Bearer ${token}`,
    };

    const dataToSend = {
      issue_id: idOfQuestion||quData.id||quData.issues[0].id,
      status: "DONE",
    };

    
    console.log(dataToSend);
    // console.log(data);

    // console.log("id sendItsDone");
    // console.log(id);
    // console.log(id);
    const serverURL = "https://emmobank.com/api/change_issue_status";

    axios
      .post(serverURL, dataToSend, { headers })
      .then((response) => {
        // console.log("Response:", response);
        setloading(false);
      })
      .catch((error) => {
        // التعامل مع الأخطاء هنا
        console.error("Error:", error);
      });
  }

  return (
    <div className="z-50  flex justify-center bg-white border border-1 p-1 border-indigo-600">
      <div dir={"ar"}>
        <ReviewModal MyImg={imgae||quData.imgae||quData.issues[0].imgae} quid={idOfQuestion||quData.id||quData.issues[0].id} />
        <button
          onClick={() => sendItsDone(quData.question_id)}
          className=" border p-2 m-1 bg-white "
        >
          {!loading ? "تم الموافقة عليه" : "جارى ارسال الطلب.. ..."}
        </button>
      </div>
    </div>
  );
}
