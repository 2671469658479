import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setStartExam, setBlur, setTimer, setModal, setModalBody, setModalBotton, setModalTitle, setBriefReport, setModalSubTitle } from '../store/actions';
import config from "../sql/config.json";
import { useNavigate, useParams } from 'react-router-dom';

export const Timer = (props) => {
    const params = useParams()
    const exams = props.exam
    const dispatch = useDispatch();
    // @ts-ignore
    const { blurExam, timer, fullReport, briefReport } = useSelector(state => state.userAppReducer);
    const [theTime, settheTime] = useState(timer)

    const timeTaken = () => {
        return 0
    }

    useEffect(() => {
        checkTimeWindow()
    }, [])


    const checkTimeWindow = ()=>{
        // window.timer = true
        const a = setInterval(() => {
            if(window.timer === true){
                startTimer(fullReport, briefReport)
                clearInterval(a);
            }
        }, 1);
    }
    function startTimer(full, brief) {
        let duration = exams.duration;
        if(localStorage.getItem("userAnswer_"+params.id) !== null && localStorage.getItem("userTime_"+params.id)  !== null){
            duration = parseInt(exams.duration)-parseInt(localStorage.getItem("userTime_"+params.id));
        }
        var timer = duration, minutes, seconds;
        // @ts-ignore
        window.timer = true        
        const stime = setInterval(() => {
            duration = duration - timeTaken()
            // @ts-ignore
            minutes = parseInt(timer / 60, 10);
            // @ts-ignore
            const pad = (n) => n < 10 ? `0${n}` : n;
            const h = Math.floor(duration / 3600);
            const m = Math.floor(duration / 60) - (h * 60);
            const s = Math.floor(duration - h * 3600 - m * 60);
            // @ts-ignore
            window.time = h + ":" + pad(m) + ":" + pad(s);
            settheTime(h + ":" + pad(m) + ":" + pad(s))
            window.userTime = window.userTime+1;
            const localtime = (localStorage.getItem("userTime_" + params.id)!=null)?parseInt(localStorage.getItem("userTime_" + params.id)):0;
            localStorage.setItem("userTime_" + params.id, localtime + 1)
            if (--timer < 0 || localStorage.getItem("stopTimerFromBackButton") === true) {
                clearInterval(stime);
                // @ts-ignore
                dispatch(setModalSubTitle(config.config[exams.lang].TimeISUp))
                // @ts-ignore
                dispatch(setModalTitle(''))
                // @ts-ignore
                dispatch(setModalBotton([
                    {
                        text: config.config[exams.lang].Retake,
                        color: 'text-white', // tail wind css color
                        border: 'border', //tail wind css border
                        borderColor: 'border-primary', //tail wind css border
                        background: 'bg-primary', //tail wind css background
                        action: reloadExam, //tail wind css background
                    }, {
                        text: config.config[exams.lang].finishExam,
                        color: 'text-white', // props wind css color
                        border: 'border', //tail wind css border
                        borderColor: 'border-primary', //tail wind css border
                        background: 'bg-primary', //tail wind css background
                        action: ViewBriefReport, //tail wind css background
                    }
                ]))
                // @ts-ignore
                dispatch(setBlur(true))
                // @ts-ignore
                dispatch(setModal(true))
            }
            // @ts-ignore
            if (window.timer == false) {
                clearInterval(stime);
            }
            duration--;
        }, 1000);
    }

    const reloadExam = () => {
        localStorage.removeItem("userTime_"+params.id)
        localStorage.removeItem("userAnswer_"+params.id)
        // @ts-ignore
        window.location.reload(false)
    };

    const ViewBriefReport = () => {
        // @ts-ignore
        dispatch(setBlur(false))
        // @ts-ignore
        dispatch(setModal(false))
        // @ts-ignore
        dispatch(setBriefReport(true))
    };


    if(props.active === true){
        return(
            <div className="w-full sticky top-[0px] z-30 h-[35px]">
                <div className={`${(blurExam === true)?'blur-sm':''} flex top-20 py-1 px-4 flex-row bg-[#d0cfdb] w-full h-[35px] justify-center item-center font-[roman] rtl:font-[Droid] gap-2 z-50`}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.413 20" className='h-6 w-6 fill-primary'>
                        <path d="m16.316 6.805 1.538-1.538A1.03 1.03 0 0 0 16.4 3.81l-1.541 1.539a1.31 1.31 0 0 0-.144.215A8.213 8.213 0 0 0 10.972 4.1V2.487H12a1.026 1.026 0 1 0 0-2.051H7.9a1.026 1.026 0 1 0 0 2.051h1.021V4.1a8.21 8.21 0 1 0 7.251 2.8 1.128 1.128 0 0 0 .144-.095zm-3.682 8.79a.986.986 0 0 1-.636.226 1.024 1.024 0 0 1-.8-.39l-2.051-2.564a1 1 0 0 1-.226-.636v-3.59a1.026 1.026 0 0 1 2.051 0v3.231l1.828 2.287a1.007 1.007 0 0 1-.166 1.436z" transform="translate(-1.741 -.436)" />
                    </svg>
                    <div className="w-fit text-md md:text-xl h-full rtl:font-[ArabicEqu]">
                        {theTime}
                    </div>
                </div>
            </div>
        );
    }else{
        return('');
    }
}