import { useEffect, useState } from "react";
 
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { firebaseApp } from "./FirebaseConfig";

import { setupNotifications } from "./FirebaseConfig";
import {
  toastNotification,
  sendNativeNotification,
} from "./notificationHelpers";
import useVisibilityChange from "./useVisibilityChange";
import { register } from "./serviceWorker";
export default function FirebaseToast() {
  const notify = () => toast("  Wow so easy!", {});

  const isForeground = useVisibilityChange();
   

  useEffect(() => {
     
    setupNotifications((message) => {
      if (isForeground) {
        // App is in the foreground, show toast notification
        toastNotification({
          title: "title",
          description: "body",
          status: "info",
        });
      } else {
        // App is in the background, show native notification
        sendNativeNotification({
          title: "title",

          body: "body ",
        });
      }
    });
  }, []);

  return (
    <div>
      {/* <button onClick={notify}>Notify!</button> */}

      <ToastContainer
        position="bottom-right"
        autoClose={2998}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}
