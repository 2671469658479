import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import langConfig from "../configuration/lang.json";
import { setLang } from "../store/actions";
import { books } from "../data";
// import { useSignOut } from "react-auth-kit";
import conf from "../../package.json";
import axios from "axios";
export const NavBar = ({
  menu,
  qr,
  bookmarkIcon,
  title,
  id,
  bookmarkTitle,
  back,
  to,
  titleText,
  switchLang,
  language,
}) => {
  const params = useParams();
   
  const navigate = useNavigate();
  // const signOut = useSignOut();
  const dispatch = useDispatch();
  const { lang, bookmark } = useSelector((state) => state.userAppReducer);
  const [currentBookmark, setcurrentBookmark] = useState([]);
  const [menuStatus, setMenuStatus] = useState(false);
  const [modalAlert, setModalAlert] = useState(false);
  const changeLang = () => {
    lang === "en" ? dispatch(setLang("ar")) : dispatch(setLang("en"));
  };
  useEffect(() => {
    setMenuStatus(false);
    setcurrentBookmark(
      bookmark.filter(
        (e) => e.subject === params.subjectID && e.bookmark.includes(true)
      ) || []
    );
  }, []);

  const gobakTo = (e) => {
    // window.history.back();

    // window.history.back();
    // localStorage.setItem("stopTimerFromBackButton", false);
    // window.timer = false;
    // if (localStorage.getItem("comeFrom")) {
    //   navigate(localStorage.getItem("comeFrom"));
    // } else {
    //   if (params.subjectID) {
    //     navigate("/lessons/" + params.subjectID);
    //   } else {
    navigate("/home");
    //   }
    // }
  };

  const clearFunction = async () => {
     

    const token = await localStorage.getItem("token");
     
    await axios
      .post(
        "https://emmobank.com/api/logout",

        {
          headers: {
            "access-token": "qwertasdfg123@+1",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
         
         
      })
      .catch((error) => {
         
         
      });

    const id = localStorage.getItem("uuid");
    const historicalDataSent = localStorage.getItem("historicalDataSent");
    localStorage.clear();
    if (id != null && historicalDataSent != null) {
      localStorage.setItem("uuid", id);
      localStorage.setItem("historicalDataSent", historicalDataSent);
    }
    // signOut();
    navigate("/login");
  };
  return (
    <div className="z-[9999999]">
      <div
        className={`flex sticky w-full top-0 px-4 py-3 bg-primary flex-col justify-between  gap-3 rounded-b-lg z-50 font-[Almarai]`}
      >
        <div className="flex flex-row-reverse justify-between w-full">
          <div className="flex w-fit">
            {/* {switchLang === false ? (
              ""
            ) : (
              <button
                onClick={() => changeLang()}
                className={`capitalize flex flex-row-reverse text-white font-[Almarai]`}
              >
                <div
                  className={`flex flex-row rtl:flex-row-reverse justify-between gap-1 divide-x-2 rtl:divide-x-2-reverse`}
                >
                  <div
                    className={`flex relative -top-1 px-2 ${
                      lang === "ar" ? "text-gray-400" : ""
                    }`}
                  >
                    ع
                  </div>
                  <div
                    className={`flex px-2 ${
                      lang === "en" ? "text-gray-400" : ""
                    }`}
                  >
                    EN
                  </div>
                </div>
              </button>
            )} */}
          </div>

          <div
            className={`flex w-full ${
              back === true || menu === true
                ? "justify-center"
                : "justify-start"
            } text-white font-[Almarai] font-bold md:text-xl relative`}
          >
            {titleText ? titleText : ""}
            {bookmarkTitle === true ? langConfig[lang].bookmark : ""}
          </div>
          <div className={`flex w-fit flex-row gap-3`}>
            {back === true ? (
              <button
                onClick={() => gobakTo(to ? to : "/")}
                className="capitalize flex flex-row gap-2 text-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className={`w-6 h-6 ${language === "ar" ? "rotate-180" : ""}`}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                  />
                </svg>
              </button>
            ) : (
              ""
            )}
            {menu === true ? (
              <button
                onClick={() => setMenuStatus(!menuStatus)}
                className="uppercase flex flex-row gap-2 text-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {menuStatus == true ? (
        <div className="fixed z-50 h-screen w-full md:w-[875px] top-0 m-auto grid grid-cols-6 ">
          <div className="bg-primary flex h-full flex-col col-span-4 gap-3 pt-20 px-3  font-[Almarai] justify-between">
            <div className="w-full flex h-full flex-col col-span-4 gap-3 px-3  font-[Almarai]">
              <button
                onClick={() => {
                  setMenuStatus(false);
                  navigate("/home");
                }}
                className="text-white flex flex-row justify-start gap-4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                  />
                </svg>
                <div>صفحة الدخول</div>
              </button>
              <button
                onClick={() => {
                  setMenuStatus(false);
                  navigate("/PrivacyPolicy");
                }}
                className="text-white flex flex-row justify-start gap-4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"
                  />
                </svg>
                <div>{langConfig[lang].PrivacyPolicy}</div>
              </button>
              <button
                onClick={() => {
                  setMenuStatus(false);
                  navigate("/terms&conditions");
                }}
                className="text-white flex flex-row justify-start gap-4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                  />
                </svg>
                <div className="w-full flex self-start text-start font-[Almarai]">
                  {langConfig[lang].TermsAndConditions}
                </div>
              </button>
              <a
                href={`mailto:exams@gpseducation.com?subject=(GPS Education Exams - v${
                  conf.APP_VERSION
                } - ${localStorage.getItem("uuid")})`}
                className="text-white flex flex-row justify-start gap-4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.712 4.33a9.027 9.027 0 011.652 1.306c.51.51.944 1.064 1.306 1.652M16.712 4.33l-3.448 4.138m3.448-4.138a9.014 9.014 0 00-9.424 0M19.67 7.288l-4.138 3.448m4.138-3.448a9.014 9.014 0 010 9.424m-4.138-5.976a3.736 3.736 0 00-.88-1.388 3.737 3.737 0 00-1.388-.88m2.268 2.268a3.765 3.765 0 010 2.528m-2.268-4.796a3.765 3.765 0 00-2.528 0m4.796 4.796c-.181.506-.475.982-.88 1.388a3.736 3.736 0 01-1.388.88m2.268-2.268l4.138 3.448m0 0a9.027 9.027 0 01-1.306 1.652c-.51.51-1.064.944-1.652 1.306m0 0l-3.448-4.138m3.448 4.138a9.014 9.014 0 01-9.424 0m5.976-4.138a3.765 3.765 0 01-2.528 0m0 0a3.736 3.736 0 01-1.388-.88 3.737 3.737 0 01-.88-1.388m2.268 2.268L7.288 19.67m0 0a9.024 9.024 0 01-1.652-1.306 9.027 9.027 0 01-1.306-1.652m0 0l4.138-3.448M4.33 16.712a9.014 9.014 0 010-9.424m4.138 5.976a3.765 3.765 0 010-2.528m0 0c.181-.506.475-.982.88-1.388a3.736 3.736 0 011.388-.88m-2.268 2.268L4.33 7.288m6.406 1.18L7.288 4.33m0 0a9.024 9.024 0 00-1.652 1.306A9.025 9.025 0 004.33 7.288"
                  />
                </svg>
                <div className="w-full flex self-start text-start font-[Almarai]">
                  للدعم والمساعدة
                </div>
              </a>
              {/* {conf.environment === true ? (
                <button
                  onClick={() => {
                    navigate("/users");
                  }}
                  className="text-white flex flex-row justify-start gap-4"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                  <div className="w-full flex self-start text-start font-[Almarai]">
                    المستخدمين
                  </div>
                </button>
              ) : (
                ""
              )} */}
              {conf.environment === true ||true? (
                <button
                  onClick={() => {
                    navigate("/Review");
                  }}
                  className="text-white flex flex-row justify-start gap-4"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z"
                    />
                  </svg>

                  <div className="w-full flex self-start text-start font-[Almarai]">
                    الاسئلة التى تم مراجعتها
                  </div>
                </button>
              ) : (
                ""
              )}

              <button
                onClick={() => setModalAlert(true)}
                className="text-white flex flex-row justify-start gap-4 mt-4 border-t-[1px] border-white pt-4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 stroke-red-500"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
                <div className="w-full flex self-start text-start font-[Almarai]">
                  {langConfig[lang].logout}
                </div>
              </button>
            </div>
            <div className="flex w-full h-fit shrink-0 text-gray-200 mb-10 justify-center font-[Almarai] py-5">
              v{conf.APP_VERSION}
            </div>
          </div>
          <div
            onClick={() => setMenuStatus(false)}
            className="bg-primary bg-opacity-40 col-span-2 flex h-full w-screen"
          ></div>
        </div>
      ) : (
        ""
      )}
      {modalAlert === true ? (
        <div className="w-screen h-screen bg-[#001670] bg-opacity-25 left-0 flex justify-center items-center align-middle content-center self-center z-50  md:max-w-[875px] right-0 fixed top-0 md:m-auto overflow-hidden">
          <div id="checkImageCount" className="hidden"></div>
          <div className="bg-white max-h-[95vh] overflow-hidden rounded-3xl w-11/12 max-w-[500px] h-fit flex flex-col  gap-4 justify-start">
            <div className="w-full h-full overflow-y-scroll overflow-hidden px-2 py-4 flex flex-col  gap-4 justify-start">
              <h1
                className={`w-full shrink-0 flex justify-center font-semibold text-center text-xl `}
              >
                {langConfig["ar"].alert}
              </h1>
              <div
                className={`font-[roman] rtl:font-[Droid] text-xl leading-[40px] sm:leading-[50px] text-center w-full`}
              >
                سوف تُمحَى جميع إجاباتك المحفوظة
              </div>
              <div className="shrink-0 h-10 flex flex-row justify-between gap-5">
                <button
                  type="button"
                  onClick={() => clearFunction()}
                  className={`text-white border bg-primary border-primary select-none text-center py-2 w-full rounded-full m-auto`}
                >
                  استمرار
                </button>
                <button
                  type="button"
                  onClick={() => setModalAlert(false)}
                  className={`text-white border bg-primary border-primary select-none text-center py-2 w-full rounded-full m-auto} rounded-full m-auto`}
                >
                  رجوع
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
