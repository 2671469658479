/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  BackNavBar,
  StartExam,
  Modal,
  Pagination,
  Controllers,
  MCQ,
  Essay,
  Paragraph,
  FullReport,
  EssayModal,
  Timer,
  EssayEvaluation,
  Login,
  Blanks,
  AccessibleScreen,
  Title,
  ResizeImage,
} from "../components";
import {
  setBlur,
  setModal,
  setExamCounter,
  setBriefReport,
  setModalBody,
  setModalTitle,
  setModalBotton,
  setTimer,
  setExamConfig,
  setStartExam,
  setModalSubTitle,
  setUserAnswer,
  setBookmark,
  setQuestionsList,
  setCurrentQues,
  setFullReport,
  setAct,
  setActualNumber,
} from "../store/actions";
import config from "../sql/config.json";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import CanvasScreen from "../canvasScreen/CanvasScreen";
import NoQuestions from "../components/examCompo/NoQuestions";
import Loading from "../components/examCompo/Loading";
import ReviewRow from "../components/ReviewCompo/ReviewRow";
import TheCorrectAnswer from "../components/ReviewCompo/TheCorrectAnswer";
import Toast from "../components/Toast";

export default function Review() {
  const params = useParams();
  const navigate = useNavigate();
  // const exams = require("../data/exams/" + params.id + ".json");
  // const isAuthenticated = useIsAuthenticated();
  const { id } = useParams();

  const dispatch = useDispatch();
  const [exams, setExams] = useState([]);
  const {
    lang,
    currentQues,
    startExam,
    blur,
    modal,
    examCounter,
    briefReport,
    fullReport,
    userAnswer,
    actualNumber,
    questionsList,
    essayModal,
    examConfig,
  } = useSelector((state) => state.userAppReducer);
  const [questions, setQuestions] = useState(exams);
  const [isLoading, setIsLoading] = useState(true);
  const [MyLoading, setMyLoading] = useState(false);
  const [reportMode, setReportMode] = useState(window.location.search);
  const [fullScoreMain, setFullScoreMain] = useState(0);
  const [fullUserScore, setFullUserScore] = useState(0);
  const [userIfAnswer, setUserIfAnswer] = useState(false);
  const [modelEssayEvaluation, setModelEssayEvaluation] = useState(false);
 

  const [accessable, setAccessable] = useState(true);
  const [images, setImages] = useState([]);
  const [currentDivision, setCurrentDivision] = useState(
    localStorage.getItem("division")
  );
  const [loading, setLoading] = useState(true);

  const [subject, setSubject] = useState();

  const mytoken = localStorage.getItem("token");
  const headers = {
    "access-token": "qwertasdfg123@+1",
    Authorization: `Bearer ${mytoken}`,
    // Authorization  : `Bearer ${mytoken}`
  };
  useEffect(() => {
    getExam();
  }, [userIfAnswer]);
  const getExam = async () => {
    // /data/exams/" + params.id + ".json
    setMyLoading(true);

const one=`https://emmobank.com/api/question_details?question_id=${params.id}`
const more=`https://emmobank.com/api/get_all`
    await axios
      .get(one, {
        headers: headers,
      })
      .then(async (respons) => {
         
        setExams(respons.data);
        setQuestions(respons.data);
        //  

        respons.data.sort((a, b) => {
          if (a.question.subject_id !== b.question.subject_id) {
            return a.question.subject_id - b.question.subject_id;
          }

          if (a.question.grade_id !== b.question.grade_id) {
            return a.question.grade_id - b.question.grade_id;
          }
          if (a.question.semester_id !== b.question.semester_id) {
            return a.question.semester_id - b.question.semester_id;
          }
          if (a.question.language_code !== b.question.language_code) {
            return a.question.language_code - b.question.language_code;
          }

          return 0;
        });

       

        return respons.data.issues;
      })
      .then((e) => {
        //  
        //  
        //  
        //  
        //  
        setTimeout(() => {
          setLoading(false);
          dispatch(setBlur(true));
          dispatch(setBriefReport(false));
          dispatch(setFullReport(false));
          dispatch(setCurrentQues(0));
          dispatch(setStartExam(false));
          dispatch(setModal(false));
          dispatch(setQuestionsList(e));
          dispatch(
            setUserAnswer(
              localStorage.getItem("userAnswer_" + params.id) !== null
                ? JSON.parse(localStorage.getItem("userAnswer_" + params.id))
                : []
            )
          );
          dispatch(
            setTimer(
              localStorage.getItem("userTime_" + params.id) !== null
                ? localStorage.getItem("userTime_" + params.id) - e.duration
                : e.duration
            )
          );
          setMyLoading(false);
          // @ts-ignore
          window.bookmark = [];
          // @ts-ignore
          window.report = [];
          // @ts-ignore
          window.time = e.duration;
          let a = localStorage.getItem("userTime_" + params.id);
          let b = e.duration;
          if (a > b) {
            localStorage.setItem("userTime_" + params.id, 0);
          }
          // @ts-ignore
          // window.userTime = (localStorage.getItem("userAnswer_" + params.id)) ? parseInt(localStorage.getItem("userTime_" + params.id)) : 0;
          setUserIfAnswer(
            localStorage.getItem("userAnswer_" + params.id)
              ? localStorage.getItem("userAnswer_" + params.id).length
              : 0
          );
          startTimerMain();
          // @ts-ignore
          //  
          //  
          dispatch(setExamCounter(e.length));
          if (
            userAnswer.length <= 0 ||
            briefReport == true ||
            fullReport == true
          ) {
            // @ts-ignore
            dispatch(setModal(false));
            dispatch(setStartExam(true));
            dispatch(setBriefReport(false));
            dispatch(setFullReport(false));
          } else {
            // @ts-ignore
            dispatch(setStartExam(false));
            // @ts-ignore
            dispatch(setModalBotton([...buttonsListForModelFinish]));
            // @ts-ignore
            dispatch(setModalTitle(""));
            // @ts-ignore
            dispatch(
              setModalSubTitle(
                config.config[exams?.meta_data?.language==="ar"?"ar":"en"].takenBody
              )
            );
            // @ts-ignore
            dispatch(setModalBody(""));
            // @ts-ignore
            dispatch(setBlur(true));
            // @ts-ignore
            dispatch(setModal(true));
          }
          // @ts-ignore
          dispatch(setExamConfig(questions));
          setTimeout(() => {
            // @ts-ignore
            setIsLoading(document.readyState === "complete" ? 0 : 1);
          }, 100);
          if (params.index) {
            dispatch(setModal(false));
            dispatch(setStartExam(false));
            dispatch(setBriefReport(false));
            dispatch(setBlur(false));
            dispatch(setCurrentQues(parseInt(params.index) - 1));
          }
        }, 10);
      })
      .catch(() => {})
      .finally(() => {});
  };

  const startTimeFrom = () => {
    window.timer = true;
  };

  const closeModel = () => {
    // @ts-ignore
    dispatch(setBlur(false));
    // @ts-ignore
    dispatch(setModal(false));
    if (exams.timer === true) {
      startTimeFrom();
    }
  };

  const buttonsListForModelFinish = [
    {
      text: config.config[exams?.meta_data?.language==="ar"?"ar":"en"].complete,
      color: "text-white", // tail wind css color
      border: "border", //tail wind css border
      borderColor: "border-primary", //tail wind css border
      background: "bg-primary",
      action: closeModel,
    },
    {
      text: config.config[exams?.meta_data?.language==="ar"?"ar":"en"].Retake,
      color: "text-[#151048]", // tail wind css color
      border: "border", //tail wind css border
      borderColor: "border-gray-300", //tail wind css border
      background: "bg-gray-300",
      action: () => {
        localStorage.removeItem("userTime_" + params.id);
        localStorage.removeItem("userAnswer_" + params.id);
        window.location.reload(false);
      },
    },
  ];

  const toggleModel = () => {
    // @ts-ignore
    dispatch(setBlur(!blur));
    // @ts-ignore
    dispatch(setModal(!modal));
  };

  const CloseModel = () => {
    // @ts-ignore
    dispatch(setBlur(false));
    // @ts-ignore
    dispatch(setModal(false));
  };

  const ViewBriefReport = () => {
    dispatch(setBlur(false));
    // @ts-ignore
    dispatch(setModal(false));
    // @ts-ignore
    setModelEssayEvaluation(false);
    dispatch(setBriefReport(true));

    // @ts-ignore
    window.timer = false;
  };

  const finishExam = () => {
    // @ts-ignore
    dispatch(
      setModalBotton([
        {
          text: config.config["ar"].yes,
          color: "text-white", // tail wind css color
          border: "border", //tail wind css border
          borderColor: "border-primary", //tail wind css border
          background: "bg-primary",
          action: ViewBriefReport,
        },
        {
          text: config.config["ar" ].no,
          color: "text-[#151048]", // tail wind css color
          border: "border", //tail wind css border
          borderColor: "border-gray-500", //tail wind css border
          background: "bg-gray-200",
          action: CloseModel,
        },
      ])
    );

    // @ts-ignore
    dispatch(setModalTitle(questions.title));
    // @ts-ignore
    dispatch(setModalSubTitle(config.config["ar"].ConfirmFinishExam));
    dispatch(setModalBody(""));
    toggleModel();
  };

  const userRight = () => {
    var num = 0;
    userAnswer.map((e) => {
      if (e && e.type) {
        num = exams.marked === true ? num + e.userScore : num + 1;
      } else {
        if (e) {
          e.map((q) => {
            //  
            if (q != null) {
              if (q[0] != null) {
                num =
                  exams.marked === true
                    ? num + q[0].userScore
                    : num + q[0].score;
              }
            }
          });
        }
      }
    });
    return num;
  };

  function startTimerMain() {
    const duration = 0;
    // const duration = exams.duration;
    const pad = (n) => (n < 10 ? `0${n}` : n);
    const h = Math.floor(duration / 3600);
    const m = Math.floor(duration / 60) - h * 60;
    const s = Math.floor(duration - h * 3600 - m * 60);
    // @ts-ignore
    window.time = h + ":" + pad(m) + ":" + pad(s);

    // @ts-ignore
    dispatch(setTimer(h + ":" + pad(m) + ":" + pad(s)));
  }
  const gotoDivision = () => {
    navigate("/home");
  };



  const notificationQuestions=localStorage.getItem("question_id");
 


  // loading

  if (false) {
    return (
      <div>  
        <Loading />
         
      </div>
    );
  }
  if (questions?.length === 0) {
    return (
      <div>
        <NoQuestions text={"NO Question to Review"} />
      </div>
    );
  }
  // return (<>ddd</>)
  // questions && !loading
  if (true) {
    return (
      <>
         {/* <CanvasScreen           language={questions?.question?.language_code   =="ar"?"ar"?"en"}        > */}
          <ReviewRow quData={questions} />

          <Toast message="تمت العملية بنجاح" type="success" />

          <div
            dir={
              questions?.[currentQues]?.question?.language_code === "ar"
                ? "rtl"
                : "ltr"
            }
            className={`w-full absolute z-10 h-screen p-0 bg-gray-100 flex flex-col font-[Almarai] md:m-auto `}
          >
            <div id="loadImagesToLocal" className="hidden"></div>
            {accessable === true ? (
              true ? (
                <>
                  {fullReport === false ? (
                    <Timer active={exams.timer} />
                  ) : (
                    <></>
                  )}

                  <div
                    dir={
                      questions?.[currentQues]?.question?.language_code === "ar"
                        ? "rtl"
                        : "ltr"
                    }
                    className={`bg-white w-full h-full
                 overflow-auto`}
                  >
                    <div
                      className={`min-h-full flex-col flex justify-between px-2 sm:px-10 pb-5`}
                    >

                      {console.log(questions.question.QuesType)}
                      {questions.question.QuesType ===
                      "Paragraph" ? (
                        <div className="w-full h-full py-4 flex flex-col">
                          <div className="w-full flex flex-col justify-start gap-4 relative">
                            {questions?.[currentQues]?.question.QuesHeadTxt !=
                            "" ? (
                              <Title
                                quesNumber={currentQues + 1}
                                question={questions?.[currentQues]?.question}
                                bookmark={false}
                              />
                            ) : (
                              <></>
                            )}

                            <div
                              className={`h-fit max-h-[27vh] overflow-auto font-['roman'] rtl:font-['Droid'] border rounded-t-md rounded-b-[20px] border-gray-300 p-2 bg-white`}
                            >
                               
                              
                              <ResizeImage
                                size="rem"
                                type="paragraph"
                                text={
                                  questions?.[currentQues]?.question?.QuesTxt
                                }
                              />
                            </div>
                            <div
                              id="paragraphBody"
                              className={`h-fit max-h-[50vh] overflow-auto border-t`}
                            >
                              <Paragraph
                                essayKey={uuidv4()}
                                index={
                                  questions?.[currentQues]?.question?.QuesID
                                }
                                lang={
                                  questions?.[currentQues]?.question
                                    ?.language_code ==="ar"?"ar":"en"
                                }
                                key={currentQues}
                                quesNumber={currentQues + 1}
                                question={questions?.[currentQues]?.question}
                              />
                              <div className="flex flex-col h-fit justify-center">
                                <Controllers
                                  lang={
                                    questions?.[currentQues]?.question
                                      ?.language_code ==="ar"?"ar":"en"
                                  }
                                />
                                <Pagination number={examCounter} type={exams} />
                                {fullReport !== true ? (
                                  <button
                                    onClick={() => finishExam()}
                                    className="w-full sm:w-52 m-auto border-primary text-white bg-primary border text-center py-2 px-4 rounded-full  font-['roman'] rtl:font-['Droid']"
                                  >
                                    {config.config["ar"==="ar"?"ar":"en"].finishExam}
                                  </button>
                                ) : (
                                  <button
                                    onClick={() =>
                                      dispatch(setBriefReport(true))
                                    }
                                    className="w-full sm:w-52 m-auto border-primary text-white bg-primary border text-center py-2 px-4 rounded-full  font-['roman'] rtl:font-['Droid']"
                                  >
                                    {config.config["ar"==="ar"?"ar":"en"].briefRprtBtn}
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="w-full pb-4 px-1 flex flex-col">

{/*                            
                          {questions.question.QuesType}
                          {console.log(questions?.question.QuesAns)} */}
                          <div>
                            {questions.question.QuesType ===
                            "mcq" ? (
                           <>
 

                           {/* {questions?.[currentQues].question.id} '' */}
 


                              <MCQ
                                id={params.id}
                                lang={questions?.question
                                    ?.language_code==="ar"?"ar":"en"
                                }
                                key={currentQues}
                                quesNumber={currentQues + 1||1}
                                question={questions?.question}
                                answers={
                                  questions?.question.QuesAns
                                }
                              />
                           
                           </>
                            ) : questions.question.QuesType ===
                              "blanks" ? (
                              <Blanks
                              id={params.id}
                              lang={
                                questions?.question
                                  ?.language_code==="ar"?"ar":"en"
                              }
                              key={currentQues}
                              quesNumber={currentQues + 1||1}
                              question={questions?.question}
                              answers={
                                questions?.question.QuesAns
                              }
                              />
                            ) : (
                              <Essay
                              id={params.id}
                              lang={
                                questions?.question
                                  ?.language_code==="ar"?"ar":"en"
                              }
                              key={currentQues}
                              quesNumber={currentQues + 1||1}
                              question={questions?.question}
                              answers={
                                questions?.question.QuesAns
                              }
                              />
                            )}
                          </div>
                        </div>
                      )}
                      <TheCorrectAnswer
                        data={questions?.question}
                      />
                    
                      {questions.question.QuesType !==
                      "Paragraph" ? (
                        <div className="flex flex-col h-fit justify-center">
                          <Controllers
                            lang={
                              questions?.[currentQues]?.question
                                ?.language_code==="ar"?"ar":"en"
                            }
                          />
                          {/* <Controllers lang={questions?.[currentQues]?.question?.language_code==="ar"?"ar":"en"} /> */}
                          <Pagination number={examCounter} type={exams} />
                          {fullReport !== true ? (
                            <button
                              onClick={() => finishExam()}
                              className="w-full sm:w-52 m-auto border-primary text-white bg-primary border text-center py-2 px-4 rounded-full  font-['roman'] rtl:font-['Droid']"
                            >
                              {config.config["ar"==="ar"?"ar":"en"].finishExam}
                            </button>
                          ) : (
                            <button
                              onClick={() => dispatch(setBriefReport(true))}
                              className="w-full sm:w-52 m-auto border-primary text-white bg-primary border text-center py-2 px-4 rounded-full  font-['roman'] rtl:font-['Droid']"
                            >
                              {config.config["ar"==="ar"?"ar":"en"].briefRprtBtn}
                            </button>
                          )}
                        </div>
                      ) : (
                        ""
                      )}

                

                       <EssayModal
                        active={essayModal}
                        lang={
                          questions?.[currentQues]?.question?.language_code ||
                          "ar"
                        }
                        exam={exams}
                      />
                      <div className="h-10" />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )
            ) : (
              <AccessibleScreen
                active={true}
                lang={lang}
                action={() => gotoDivision()}
              />
            )}
          </div>
        {/* </CanvasScreen> */}
      </>
    );
  } else {
    return (
      <>
        <Loading />
      </>
    );
  }
}
