import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { setBookmark } from '../store/actions';
import { useParams } from 'react-router-dom';

export const Title = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { currentQues, bookmark, questionsList } = useSelector(state => state.userAppReducer);
  const exams = props.exam
  const [currentBookmark, setcurrentBookmark] = useState(bookmark.filter(e => e.lesson === parseInt(params.id))||[]);
  const [cuerrentPage, setcuerrentPage] = useState([currentQues,(currentBookmark && currentBookmark[0] && currentBookmark[0].bookmark[currentQues] === true)?true:false]);
  useEffect(() => {
    setcurrentBookmark(bookmark.filter(e => e.lesson === parseInt(params.id))||[])
    try {
      const i = JSON.parse(localStorage.getItem("bookmark")).filter(e=>e.lesson === params.id);
        setcuerrentPage([currentQues,i[0].bookmark[currentQues]||false])
    } catch (error) {
      setcuerrentPage([currentQues,false])
    }
  }, []);

  const bookmarkAction = async () => {
    if (bookmark.length > 0) {
      if(bookmark.filter(e => e.lesson === params.id).length > 0){
        if(currentBookmark && currentBookmark[0] && currentBookmark[0].bookmark[currentQues] === true){
          let book = bookmark.filter(e => e.lesson === params.id)
          let index = bookmark.findIndex(e => e.lesson === params.id)
          bookmark[index].bookmark[currentQues] = false;
          await setcuerrentPage([currentQues, false])
           
          dispatch(setBookmark(bookmark))
        }else{
          let book = bookmark.filter(e => e.lesson === params.id)
          let index = bookmark.findIndex(e => e.lesson === params.id)
          bookmark[index].bookmark[currentQues] = true;
          await setcuerrentPage([currentQues, true])
           
          await dispatch(setBookmark(bookmark))
        }
      }else{
        let item = {
          name: exams.title,
          subject: params.subjectID,
          lesson: params.id,
          questionsList: questionsList.length,
          bookmark: Array(questionsList.length).fill(false),
        }
        item.bookmark[currentQues] = true;
        await setcuerrentPage([currentQues, true])
         
        await bookmark.push(item)
        await dispatch(setBookmark(bookmark))
      }
    } else {
      let item = {
        name: exams.title,
        subject: params.subjectID,
        lesson: params.id,
        questionsList: questionsList.length,
        bookmark: Array(questionsList.length).fill(false),
      }
      item.bookmark[currentQues] = true;
      await setcuerrentPage([currentQues, true])
      await dispatch(setBookmark([{ ...item }]))
    }

    await setcurrentBookmark( bookmark.filter(e => e.lesson === params.id)||bookmark)
    await localStorage.setItem("bookmark",JSON.stringify(bookmark))
  }

  const replacementTitle = (text) => {
    let last = "";
    text?.split(':').map(
      e => (e && (e !== '' || e !== 'undefined')) ? (last += e + "<bdi>:</bdi>") : ''
    )
    return last
  }

  if(props.question?.QuesHeadTxt==""){
    return <></>
  }else{

    return (
      <div className='flex flex-row justify-start gap-1 text-[#151048] text-md font-[roman] rtl:font[Droid] font-bold' style={{'letterSpacing':-1}}>
        {
          (props.bookmark === true) ? (
            <button onClick={() => bookmarkAction()} className={`flex justify-start`}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={
                `w-6 h-6 stroke-primary ${((currentBookmark && currentBookmark[0] && currentBookmark[0].bookmark[currentQues] === true)|| (cuerrentPage[0] === currentQues && cuerrentPage[1] === true)) ? ('fill-primary') : ('fill-white')}
                ${(cuerrentPage[0] === currentQues && cuerrentPage[1] === true)?'fill-primary':''}
                `}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z" />
              </svg>
            </button>
          ) : ''
        }
        <span className="font-['roman']  shrink-0 rtl:font-['ArabicEqu']">{props.quesNumber} : </span>
        <span className="font-['roman'] rtl:font-['Droid'] text-left rtl:text-right font-wrap" dangerouslySetInnerHTML={{ __html: replacementTitle(props.question?.QuesHeadTxt) }}></span>
      </div>
    )
  }

}