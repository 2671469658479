import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Home,
  Subjects,
  Exam,
  NotFound,
  BookMark,
  TermsAndConditions,
  ExamReport,
  PrivacyPolicy,
  Users,
  Lessons,
} from "./pages";
import FirebaseToast from "./components/firebase/FirebaseToast";
import config from "../package.json";
import Login from "./pages/Login";
import { ProtectedLayout } from "./layouts/protectedLayout";
import Review from "./pages/Review";
import ReviewnotificationQuestions from "./pages/ReviewnotificationQuestions";
import QuestionDetails from "./pages/QuestionDetails";
import { NavBar } from "./components";
 
const root = ReactDOM.createRoot(document.getElementById("root"));

const handleCallback = (message) => {
   
};

 // {permission !== "granted" &&  <>permission</>}

 




root.render(
  <Provider store={store}>
    <React.StrictMode>



      <FirebaseToast callback={handleCallback} />
 
      <BrowserRouter>
        <App />
        {/* <NavBar/> */}

        <Routes>
          {/* if(false){<Route path="/Login" element={<Login />} />} */}
          <Route path="/Login" element={<Login />} />
          <Route element={<ProtectedLayout />}>
            <Route exact path="/" element={<App />} />

            <Route path="/home" element={<Home />} />
            <Route path="/subjects/:school/:division" element={<Subjects />} />
            <Route path="/lessons/:subjectID" element={<Lessons />} />
            <Route path="/Review" element={<Review />} />
            <Route path="/ReviewnotificationQuestions/:id" element={<ReviewnotificationQuestions />} />
            <Route path="/exam/:subjectID/:id" element={<Exam />} />
            <Route path="/exam/:subjectID/:id/:index" element={<Exam />} />
            <Route path="/question_details/:question_id" element={<QuestionDetails />} />
            <Route
              path="/exam/:subjectID/:id/full-report"
              element={<ExamReport />}
            />
            <Route
              path="/exam/:subjectID/:id/:index/full-report"
              element={<ExamReport />}
            />
            <Route path="/bookmark/:subjectID" element={<BookMark />} />
            <Route path="/terms&conditions" element={<TermsAndConditions />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          </Route>
          <Route path="/exam/notfound" element={<NotFound />} />
          {config.environment == true ? (
            <Route path="/users" element={<Users />} />
          ) : (
            "< />"
          )}
          <Route path="*" element={<NotFound />} />
          <Route component={NotFound} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </Provider>
);

reportWebVitals();
