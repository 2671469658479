import React, { useState, useEffect } from 'react';

const Toast = ({ message, type }) => {
  const [isVisible, setIsVisible] = useState(true);

  let bgColor = '';
  switch (type) {
    case 'success':
      bgColor = 'bg-green-500';
      break;
    case 'error':
      bgColor = 'bg-red-500';
      break;
    case 'warning':
      bgColor = 'bg-yellow-500';
      break;
    default:
      bgColor = 'bg-gray-500';
      break;
  }






  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 1000); // مدة ظهور التوست بالدقائق (هنا 60 ثانية أو دقيقة واحدة)

    return () => {
      clearTimeout(timer);
    };
  }, []);














  
  return (
    <div  >
       {/* {isVisible && (
        <div className={` z-50  fixed top-15 right-5 p-4 rounded-md ${bgColor} text-white`}>
         sdfsdf
        message
        </div>
      )} */}
    </div>
  );
};

export default Toast;
