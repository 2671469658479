import React, { useEffect, useState } from "react";

import { Login } from "./components";
// import { useIsAuthenticated } from 'react-auth-kit'
import { useNavigate } from "react-router-dom";
import GPSLogo from "./GPS-Logo.svg";
import notification from "./notification.png";
import { registerServiceWorker } from "./components/firebase/serviceWorker";
import { setupNotifications } from "./components/firebase/FirebaseConfig";

 
function App(props) {
  const navigate = useNavigate();
  // const isAuthenticated = useIsAuthenticated()
  const [activeLogin, setActiveLogin] = useState(true);
  const [IsHidden, setIsHidden] = useState(false);
  const [startAnimation, setStartAnimation] = useState(false);

  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
  if (path) {
    navigate.replace(path);
  }

  useEffect(() => {
    setTimeout(() => {
      setStartAnimation(true);
    }, 500);
    setTimeout(() => {
      // navigate('/home')
      setIsHidden(true);
    }, 3000);
  }, []);

  const [permission, setPermission] = useState("default");

  useEffect(() => {
    const getPermission = async () => {
      const result = await Notification.requestPermission();
       
      setPermission(result);
    };

    getPermission();

    navigate(window.location.pathname==="/"?"/home":window.location.pathname);
    
  }, []);

  const getPermission = async () => {
     
    const result = await Notification.requestPermission();
    setPermission(result);
  };



  useEffect(() => {
    registerServiceWorker()
      .then((registration) => {
        setupNotifications(registration);
      })
      .catch((error) => {
        console.error("Failed to register service worker:", error);
      });
  }, []);
  return (
    <div>
      <div
        className={`flex ${
          permission === "granted" ? "hidden" : ""
        }  fixed z-[999999] top-0 left-0 w-screen h-screen bg-white  justify-center flex-col gap-6  items-center`}
      >
        {permission === "default" && (
          <div className="flex flex-col">
            ننتظر موافقتك على السماح لتفعيل الاشعارات{" "}
            <button
              className="text-red-600 border rounded-xl flex flex-row items-center py-2 gap-2 justify-around  "
              onClick={() => window.location.reload()}
            >
            إعادة الطلب
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
            </button>
          </div>
        )}
        {permission === "granted" && <p>Permission granted!</p>}
        {permission === "denied" && (
          <div className="flex fixed top-10 left-0 w-screen h-screen flex-col justify-center ">
            <h1 className="text-3xl text-red-600 p-9">
              يجب ان توافق على الاشعارات اولا{" "}
            </h1>
            <img src={notification} alt="My Image" />
          </div>
        )}
      </div>
      <div
        className={`fixed justify-center top-0 left-0 ${
          IsHidden ? "hidden" : ""
        }   bg-white h-screen w-screen z-[99999999] flex flex-col    pt-[15vh]`}
      >
        {/* <Login active={activeLogin} to={"/home"} /> */}

        <div
          className={`flex w-full h-fit justify-center flex-col gap-6  items-center`}
        >
          <img
            className={`h-auto w-[200px] block ease-in-in duration-500 delay-100 ${
              startAnimation === false ? "opacity-0 -mt-10" : "opacity-1 -mt-0"
            }`}
            alt="GPS education"
            src={GPSLogo}
          />
          {/* <div className="h-10"></div> */}
          {/* <img className={`h-auto w-[200px] block ease-in-in duration-500 delay-500 ${(startAnimation === false) ? 'opacity-0 -mt-10' : 'opacity-1 -mt-0'}`} alt="المعاصر" src="./bg/logo_1.svg" /> */}
          {/* <img className={`h-auto w-[200px] block ease-in-in duration-500 delay-700 ${(startAnimation === false) ? 'opacity-0 -mt-10' : 'opacity-1 -mt-0'}`} alt="الإمتحان" src="./bg/logo_2.svg" /> */}
          {/* <img className={`h-auto w-[200px] block ease-in-in duration-500 delay-1000 ${(startAnimation === false) ? 'opacity-0 -mt-10' : 'opacity-1 mt-3'}`} alt='el-moasser' src="./bg/logo_3.svg" /> */}
        </div>
      </div>
    </div>
  );
}

export default App;
