// Accordion.js
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";

export default function Accordion({ items }) {
  const params = useParams();
  const navigate = useNavigate();

  // scoring[item.index] = JSON.parse(
  //   localStorage.getItem("userAnswer_" + item.id) || false
  // );
  // retake[item.index] = JSON.parse(
  //   localStorage.getItem("retake_" + item.id) || false
  // );
  // setScoring(scoring);
  // setRetake(retake);

  const goToFunction = (item, link, score, index) => {
    var link = "/exam/" + params.subjectID + "/" + link.id;
     
    navigate(link);
    toggleAccordion(index);
  };

   
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const [retake, setRetake] = useState([]);
  const [scoring, setScoring] = useState(
    JSON.parse(localStorage.getItem("scoring_" + params.subjectID)) || []
  );

  function addToLocall(item) {
     
    scoring[item.index] = JSON.parse(
      localStorage.getItem("userAnswer_" + item.id) || false
    );
    retake[item.index] = JSON.parse(
      localStorage.getItem("retake_" + item.id) || false
    );
  }

  return (
    <div>
      {items.map((item, index) => (
        <div
          onClick={addToLocall(item)}
          className={` p-1  ml-3 m ${
            activeIndex === index && "rounded-xl	 bg-gray-200 "
          }`}
          key={index}
        >
          <div
            onClick={() => {
              toggleAccordion(index);
            }}
            key={item.id}
            className={`${
              1 > 0 ? "bg-white" : "bg-gray-200"
            } min-h-14 h-fit border shadow-md rounded-lg flex flex-row gap-3 justify-start items-center overflow-hidden`}
          >
            <button
              disabled={1 <= 0}
              className="w-full flex h-full items-center"
            >
              {
                <>
                  {item?.childs.length !== 0 ? (
                    <div
                      className={`
                            felx mx-3 w-6 h-6 shrink-0
                           
                        `}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={activeIndex === index ? 30 : 20}
                        height={activeIndex === index ? 30 : 20}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className={`${
                          activeIndex === index
                            ? "transform rotate-[90deg]"
                            : ""
                        }   mx-4 w-6 h-6 
                        icon ${
                          activeIndex === index ? "text-blue-800" : ""
                        }  icon-tabler icons-tabler-outline icon-tabler-corner-down-right-double`}
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M4 5v6a3 3 0 0 0 3 3h7" />
                        <path d="M10 10l4 4l-4 4m5 -8l4 4l-4 4" />
                      </svg>
                    </div>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className={`
                            felx mx-2 w-6 h-6 shrink-0
                            ${
                              true
                                ? "stroke-white fill-primary"
                                : "fill-white stroke-primary"
                            }
                        `}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />

                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  )}
                </>
              }

              <h1 className="w-full flex text-start p-2 text-[#151048] font-[Almarai]">
                {item?.name}
                {/* {console.log(item.childs)} */}
              </h1>

              {item?.childs.length === 0 ? (
                <>
                  {retake[item.index] === true ? (
                    <div className="relative flex flex-row justify-end mx-2 w-32 border rounded-full h-3 bg-gray-200">
                      <div className="w-4/12 bg-primary rounded-full"></div>
                    </div>
                  ) : scoring[item.index] ? (
                    <div className="relative flex flex-row justify-end mx-2 w-32 border rounded-full h-3 bg-gray-200">
                      <div className="w-full bg-primary rounded-full"></div>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : null}

              <button
                onClick={() =>
                  goToFunction(
                    index,
                    item,
                    "link",
                    JSON.parse(
                      localStorage.getItem("userAnswer_" + item.id)
                        ? true
                        : false
                    )
                  )
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 m-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                  />
                </svg>
              </button>
            </button>
          </div>

          {activeIndex === index && (
            <div className="animate-flip-down animate-duration-[3993ms]">
              <Accordion items={item.childs} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
