import React, { useEffect, useState, useCallback, memo } from "react";
import { ResizeImage, Modal, Title } from "./";
import config from "../sql/config.json";
import { useSelector, useDispatch } from "react-redux";
import {
  setUserAnswer,
  setModalBotton,
  setModalTitle,
  setModalBody,
  setBlur,
  setModal,
  setEssayModal,
  setEssayModalBody,
  setEssayModalButton,
  setEssayModalTitle,
  setEssayModalUser,
} from "../store/actions";
import $ from "jquery";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useParams } from "react-router-dom";

export const Paragraph = memo((props) => {
  const params = useParams();
  const pageId = props.essayKey;
  // const { exams } = require('../sql/data');
  const exams = [];
  const { index } = props;
  const { modal, blurExam, currentQues, fullReport, userAnswer, briefReport, examConfig } =
    useSelector((state) => state.userAppReducer);
  const [paragraph, setParagraph] = useState({ status: true, index: index });
  const dispatch = useDispatch();

  const [current_is_answerd, setCurrent_is_answerd] = useState(null)

  useEffect(()=>{
    setCurrent_is_answerd(userAnswer[currentQues]);
     
    setTimeout(() => {
      setParagraph({ status: !paragraph.status, index: index });
    }, 50);
  }, [currentQues]);
  const myAnswer = (answer, current, index, key, quez) => {
    if (userAnswer[current]) {
      userAnswer[current][key] = [
        {
          index: index,
          answer: answer,
          right: quez.QuesRightAns,
          type: quez.QuesType,
          parent: true,
          score: parseInt(quez.QuesScore),
          userScore:
            quez.QuesRightAns === answer ? parseInt(quez.QuesScore) : 0,
        },
      ];
    } else {
      userAnswer[current] = [];
      userAnswer[current][key] = [
        {
          index: index,
          answer: answer,
          right: quez.QuesRightAns,
          type: quez.QuesType,
          parent: true,
          score: parseInt(quez.QuesScore),
          userScore:
            quez.QuesRightAns === answer ? parseInt(quez.QuesScore) : 0,
        },
      ];
    }

    localStorage.setItem("userAnswer_" + params.id, JSON.stringify(userAnswer));
    localStorage.setItem('retake_' + params.id, true)
    // @ts-ignore
    dispatch(setUserAnswer(userAnswer));
  };
  const AnswerIcon = ({
    point,
    kesy,
    index,
    roundeed,
    numeric_type,
    txt,
    style,
    quez,
  }) => {
    if (userAnswer[currentQues] && userAnswer[currentQues][kesy]) {
      if (
        point === userAnswer[currentQues][kesy][0].answer &&
        point === userAnswer[currentQues][kesy][0].right
      ) {
        return (
          <div
            className={`w-full select-none  flex ${numeric_type === 1 ? "flex-row-reverse" : "flex-row"
              } justify-start items-start content-start self-start gap-3`}
          >
            <div
              className={`bg-[#288D57] shrink-0 text-white  ${roundeed} relative shrink-0  w-7 h-7 flex justify-center items-center mt-2 font-['roman']  rtl:font-['Droid']`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
                {/* <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" /> */}
              </svg>
            </div>
            <ResizeImage size="rem" type="answer" text={txt} />
          </div>
        );
      } else {
        if (
          point != userAnswer[currentQues][kesy][0].answer &&
          point === userAnswer[currentQues][kesy][0].right
        ) {
          return (
            <div
              className={`w-full select-none  flex ${numeric_type === 1 ? "flex-row-reverse" : "flex-row"
                } justify-start items-start content-start self-start gap-3`}
            >
              <div
                className={`bg-[#288D57] shrink-0 text-white  ${roundeed} relative shrink-0  w-7 h-7 flex justify-center items-center mt-2 font-['roman']  rtl:font-['Droid']`}
              >
                {point}
              </div>
              <ResizeImage size="rem" type="answer" text={txt} />
            </div>
          );
        } else if (
          point === userAnswer[currentQues][kesy][0].answer &&
          point != userAnswer[currentQues][kesy][0].right
        ) {
          return (
            <div
              className={`w-full select-none  flex ${numeric_type === 1 ? "flex-row-reverse" : "flex-row"
                } justify-start items-start content-start self-start gap-3`}
            >
              <div
                className={`bg-[#AB2620] shrink-0 text-white  ${roundeed} relative shrink-0  w-7 h-7 flex justify-center items-center mt-2 font-['roman']  rtl:font-['Droid']`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  {/* <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" /> */}
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
              <ResizeImage size="rem" type="answer" text={txt} />
            </div>
          );
        } else {
          return (
            <div
              className={`w-full select-none  flex ${numeric_type === 1 ? "flex-row-reverse" : "flex-row"
                } justify-start items-start content-start self-start gap-3`}
            >
              <div
                className={` ${quez.QuesRightAns === point
                  ? "bg-[#288D57] shrink-0 text-white"
                  : style.color +
                  " " +
                  style.background +
                  " " +
                  style.border +
                  " " +
                  style.borderColor
                  } ${style.rounded
                  } relative shrink-0  w-7 h-7 flex justify-center items-center mt-2 font-['roman'] rtl:font-['Droid']`}
              >
                {point}
              </div>
              <ResizeImage size="rem" type="answer" text={txt} />
            </div>
          );
        }
      }
    } else {
      return (
        <div
          className={`w-full select-none  flex ${numeric_type === 1 ? "flex-row-reverse" : "flex-row"
            } justify-start items-start content-start self-start gap-3`}
        >
          <div
            className={` ${quez.QuesRightAns === point
              ? "bg-[#288D57] shrink-0 text-white"
              : style.color +
              " " +
              style.background +
              " " +
              style.border +
              " " +
              style.borderColor
              } ${style.rounded
              } relative shrink-0  w-7 h-7 flex justify-center items-center mt-2 font-['roman'] rtl:font-['Droid']`}
          >
            {point}
          </div>
          <ResizeImage size="rem" type="answer" text={txt} />
        </div>
      );
    }
  };
  const essayMyAnswer = (answer, current, index, quez, input) => {
    if (!userAnswer[current]) {
      userAnswer[current] = [];
      userAnswer[current][index] = [];
    }
    userAnswer[current][index] = [
      {
        index: index,
        answer: answer,
        right: quez.QuesRightAns,
        type: quez.QuesType,
        parent: true,
        score: parseInt(quez.QuesScore),
        userScore: null,
      },
    ];
    localStorage.setItem("userAnswer_" + params.id, JSON.stringify(userAnswer));
    localStorage.setItem('retake_' + params.id, true)
    // // @ts-ignore
    dispatch(setUserAnswer(userAnswer));
  };
  const mathConfig = {
    loader: { load: ["ui/lazy"] },
    asciimath: { displaystyle: true },
  };
  const toggleModel = (title, body) => {
    // @ts-ignore
    dispatch(setModalBotton([...buttonsListForModelFinish]));
    // @ts-ignore
    dispatch(setModalTitle(title));
    // @ts-ignore
    dispatch(setModalBody(body));
    // @ts-ignore
    dispatch(setBlur(!blurExam));
    // @ts-ignore
    dispatch(setModal(!modal));
  };
  const toggleEssayModel = (title, body, user, quez, key) => {
    // @ts-ignore
    dispatch(setEssayModalButton([...buttonsListForModelFinish]));
    // @ts-ignore
    dispatch(setEssayModalTitle(title));
    // @ts-ignore
    dispatch(setEssayModalBody({ parent: true, key: key, ...quez }));
    // @ts-ignore
    dispatch(setBlur(!blurExam));
    // @ts-ignore
    dispatch(setEssayModalUser(user));
    // @ts-ignore
    dispatch(setEssayModal(!modal));
  };
  const closeModel = () => {
    // @ts-ignore
    dispatch(setBlur(false));
    // @ts-ignore
    dispatch(setEssayModal(false));
    // @ts-ignore
    dispatch(setModal(false));
  };
  const buttonsListForModelFinish = [
    {
      text: config.config[props.lang].BackExam,
      color: "text-white", // tail wind css color
      border: "border", //tail wind css border
      borderColor: "border-primary", //tail wind css border
      background: "bg-primary", //tail wind css background
      action: closeModel, //tail wind css background
    },
  ];
  const EssayMyAnswer = ({ id, index }) => {
    let data = localStorage.getItem("essayAnswer");
    if (data != null) {
      let obj = JSON.parse(data);
      if (obj[currentQues]) {
        if (
          obj[currentQues][index] &&
          obj[currentQues][index][id] &&
          obj[currentQues][index][id].local === false
        ) {
          userAnswer[currentQues][index] = [{ ...obj[currentQues][index][id] }];
          dispatch(setUserAnswer(userAnswer));
          obj[currentQues][index][id].local = true;
          localStorage.setItem("essayAnswer", JSON.stringify(obj));
          localStorage.setItem('retake_' + params.id, true)
        }
      }
    }
  };

  return (
      <div className="">
        {props.question.list
          .sort(function (a, b) {
            return a.Sort - b.Sort;
          })
          .map((quez, key) =>
            quez.QuesType === "mcq" ? (
              <div key={uuidv4()} className="w-full flex flex-col pt-4">
                <div className="w-full flex flex-col justify-start gap-4">
                  {
                    props.quesNumber.QuesHeadTxt!=""&&<Title
                          quesNumber={key + 1}
                          question={quez}
                          bookmark={false}
                        />
                  }

                  <div className="border rounded-lg border-gray-300 p-2 bg-white relative">
                    {(fullReport === false && examConfig.marked === true) ? (
                      <div
                        className={`absolute text-sm py-1 -top-[19px] border bg-white ${props.lang == "ar"
                          ? "left-5 font-[Droid]"
                          : "right-5 font-[roman]"
                          } px-5 rounded-full gap-1 flex flex-row z-10`}
                      >
                        <span
                          className={`${props.lang === "ar"
                            ? "left-5 font-[ArabicEqu]"
                            : "right-5 font-[roman]"
                            } flex`}
                        >
                          {props.lang === "ar"
                            ? ![1, 2].includes(
                              parseInt(props.question.QuesScore)
                            )
                              ? quez.QuesScore
                              : ""
                            : quez.QuesScore}
                        </span>
                        <span className="flex font-['roman'] rtl:font-['Droid']">
                          {props.lang === "ar"
                            ? parseInt(quez.QuesScore) === 1
                              ? "درجة واحدة"
                              : parseInt(quez.QuesScore) === 2
                                ? "درجتان"
                                : config.config["ar"].Degrees
                            : [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].includes(
                              parseInt(quez.QuesScore)
                            )
                              ? config.config["en"].Degrees
                              : config.config["en"].Degree}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    <ResizeImage
                      size="rem"
                      type="ques"
                      text={quez.QuesTxt}
                    />
                    <div className="h-1"></div>
                    <div className="flex flex-col justify-start gap-3 ">
                      {quez.QuesAns.map((item, ind) =>
                        fullReport === true && briefReport === false ? (
                          // @ts-ignore
                          <AnswerIcon
                            quez={quez}
                            key={uuidv4()}
                            kesy={key}
                            index={ind}
                            style={item.buttons}
                            point={item.buttons.point}
                            txt={item.txt}
                            roundeed={item.buttons.rounded}
                            numeric_type={
                              quez.numeric_type && quez.numeric_type === 1
                                ? 1
                                : 0
                            }
                          />
                        ) : (
                          <button
                            key={ind}
                            onClick={() =>
                              myAnswer(
                                item.buttons.point,
                                currentQues,
                                ind,
                                key,
                                quez
                              )
                            }
                            className={`w-fit select-none  flex ${quez.numeric_type && quez.numeric_type === 1
                              ? "flex-row-reverse"
                              : "flex-row"
                              } justify-start items-start content-start self-start gap-3`}
                          >
                            <div
                              className={`${index === 0 ? "rtl:pt-[2px]" : ""
                                } ${userAnswer[currentQues] &&
                                  userAnswer[currentQues][key] &&
                                  userAnswer[currentQues][key][0].index ===
                                  ind &&
                                  userAnswer[currentQues][key][0].answer ===
                                  item.buttons.point
                                  ? item.buttons.activeColor +
                                  " " +
                                  item.buttons.activeBackground
                                  : item.buttons.color +
                                  " " +
                                  item.buttons.background
                                } ${item.buttons.border +
                                " " +
                                item.buttons.borderColor +
                                " " +
                                item.buttons.rounded
                                } relative  w-7 h-7 shrink-0 flex justify-center items-center mt-2`}
                            >
                              {item.buttons.point}
                            </div>
                            <ResizeImage
                              size="rem"
                              type="answer"
                              text={item.txt}
                            />
                          </button>
                        )
                      )}
                    </div>
                    {fullReport === true ? (
                      <>
                        <br />
                        <div
                          className={`flex flex-row gap-4  ${briefReport === false &&
                            quez.QuesShowAns1.status === true
                            ? "justify-between"
                            : "justify-end"
                            }`}
                        >
                          {briefReport === false &&
                            quez.QuesShowAns1.status === true ? (
                            <button
                              onClick={() => {
                                toggleModel(
                                  exams.showAnserButton[props.lang],
                                  quez.QuesShowAns1.txt
                                );
                              }}
                              className="w-fit border-primary bg-white text-[#151048] mb-3 border text-center py-2 px-4 rounded-full"
                            >
                              {exams.showAnserButton[props.lang]}
                            </button>
                          ) : (
                            ""
                          )}
                          {
                            (examConfig.marked === true) ? (
                              <div
                                className={`${props.lang == "ar"
                                  ? "left-5 font-[ArabicEqu]"
                                  : "right-5 font-[roman]"
                                  } w-fit border-primary bg-white text-[#151048] h-fit mt-1 border-gray-400 text-gray-400 border text-center py-1 px-4 rounded-full flex flex-row-reverse gap-2`}
                              >
                                <div className={`flex`}>{quez.QuesScore}</div>
                                <div className={`flex`}>
                                  {props.lang == "ar" ? "\\" : "/"}
                                </div>
                                <div className={`flex`}>
                                  {current_is_answerd &&
                                    current_is_answerd[key] &&
                                    current_is_answerd[key][0].userScore !=
                                    null
                                    ? current_is_answerd[key][0].userScore
                                    : 0}
                                </div>
                              </div>

                            ) : ''
                          }
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-full pt-4 flex flex-col" key={uuidv4()}>
                <EssayMyAnswer id={quez.QuesID} index={key} />
                <div className="w-full flex flex-col justify-start gap-4">
                
                  
                  {
                    props.quesNumber.QuesHeadTxt!=""&&<Title
                    quesNumber={key + 1}
                    question={quez}
                    bookmark={false}
                  />
                  }

                  <div className="border rounded-lg border-gray-300 flex flex-col p-2 gap-4 relative">
                    {(fullReport === false && examConfig.marked === true) ? (
                      <div
                        className={`absolute text-sm py-1 -top-[19px] border bg-white ${props.lang == "ar"
                          ? "left-5 font-[Droid]"
                          : "right-5 font-[roman]"
                          } px-5 rounded-full gap-1 flex flex-row z-10`}
                      >
                        <span
                          className={`${props.lang === "ar"
                            ? "left-5 font-[ArabicEqu]"
                            : "right-5 font-[roman]"
                            } flex`}
                        >
                          {props.lang === "ar"
                            ? ![1, 2].includes(parseInt(quez.QuesScore))
                              ? quez.QuesScore
                              : ""
                            : quez.QuesScore}
                        </span>
                        <span className="flex">
                          {props.lang === "ar"
                            ? parseInt(quez.QuesScore) === 1
                              ? "درجة واحدة"
                              : parseInt(quez.QuesScore) === 2
                                ? "درجتان"
                                : config.config["ar"].Degrees
                            : [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].includes(
                              parseInt(quez.QuesScore)
                            )
                              ? config.config["en"].Degrees
                              : config.config["en"].Degree}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    <ResizeImage
                      size="rem"
                      type="ques"
                      text={quez.QuesTxt}
                    />
                    {fullReport === true && briefReport === false ? (
                      current_is_answerd &&
                        current_is_answerd[key] &&
                        current_is_answerd[key][0].answer ? (
                        <div className="w-full bg-gray-200 text-gray-600 border rounded-lg p-2 border-primary opacity-50 border-opacity-30">
                          {current_is_answerd &&
                            current_is_answerd[key]
                            ? current_is_answerd[key][0].answer || ""
                            : ""}
                        </div>
                      ) : (
                        <div className="w-full bg-gray-200 text-gray-600 border rounded-lg p-2 border-primary opacity-50 border-opacity-30">
                          {config.config[props.lang].noEssayAnswerUser}
                        </div>
                      )
                    ) : (
                      ""
                    )}
                    {fullReport === true && briefReport === false ? (
                      <div
                        className={`flex flex-row gap-4 ${briefReport === false &&
                          quez.QuesShowAns1.status === true
                          ? "justify-between"
                          : "justify-end"
                          }`}
                      >
                        <button
                          onClick={() => {
                            toggleEssayModel(
                              config.config[props.lang].TheModelAnswer,
                              quez.QuesShowAns1.txt,
                              current_is_answerd &&
                                current_is_answerd[key]
                                ? current_is_answerd[key][0].answer
                                : "",
                              quez,
                              key
                            );
                          }}
                          className="w-fit border-primary bg-white text-[#151048] mb-3 border text-center py-2 px-4 rounded-full"
                        >
                          {config.config[props.lang].ShowModelAnswer}
                        </button>
                        {
                          (examConfig.marked === true) ? (
                            <div
                              className={`${props.lang == "ar"
                                ? "left-5 font-[ArabicEqu]"
                                : "right-5 font-[roman]"
                                } w-fit border-primary bg-white text-[#151048] h-fit mt-1 border-gray-400 text-gray-400 border text-center py-1 px-4 rounded-full flex flex-row-reverse gap-2`}
                            >
                              <div className={`flex`}>{quez.QuesScore}</div>
                              <div className={`flex`}>
                                {props.lang == "ar" ? "\\" : "/"}
                              </div>
                              <div className={`flex`}>
                                {userAnswer[currentQues] &&
                                  userAnswer[currentQues][key] &&
                                  userAnswer[currentQues][key][0].userScore !=
                                  null
                                  ? userAnswer[currentQues][key][0].userScore
                                  : 0}
                              </div>
                            </div>
                          ) : ''
                        }
                      </div>
                    ) : (
                      <textarea
                        id={quez.QuesID}
                        onChange={(e) => {
                          if (!userAnswer[currentQues]) {
                            userAnswer[currentQues] = [];
                            userAnswer[currentQues][index] = "";
                          }
                          let a = {
                            [quez.QuesID]: {
                              index: key,
                              answer: e.target.value,
                              right: quez.QuesRightAns,
                              type: quez.QuesType,
                              parent: true,
                              score: parseInt(quez.QuesScore),
                              userScore: 0,
                              local: false,
                            },
                          };
                          let b = JSON.parse(
                            localStorage.getItem("essayAnswer")
                          );
                          if (b === null) {
                            b = [];
                            b[currentQues] = [];
                          }
                          if (!b[currentQues]) {
                            b[currentQues] = [];
                          }
                          b[currentQues][key] = a;
                          localStorage.setItem(
                            "essayAnswer",
                            JSON.stringify(b)
                          );
                        }}
                        rows={6}
                        placeholder={
                          config.config[props.lang].EssayPlaceHolder
                        }
                        className="w-full border-primary bg-white text-[#151048] focus:border-primary border border-opacity-40 py-1 px-2 flex font-['roman'] rtl:font-['Droid'] rounded-xl"
                        defaultValue={
                          userAnswer[currentQues] &&
                          userAnswer[currentQues][key] &&
                          userAnswer[currentQues][key][0] &&
                          userAnswer[currentQues][key][0].answer
                        }
                      ></textarea>
                    )}
                  </div>
                </div>
                <Modal active={modal} buttons={buttonsListForModelFinish} />
              </div>
            )
          )}
      </div>
  );
});
