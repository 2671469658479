import React from "react";
import { BackNavBar } from "../BackNavBar";
import { useSelector, useDispatch } from "react-redux";

export default function Loading() {
  const { lang } = useSelector((state) => state.userAppReducer);
  return (
    <div>
      <div
        dir={lang === "ar" ? "rtl" : "ltr"}
        className="flex flex-col justify-start h-full w-full p-0 m-0 md:max-w-[875px] m-auto overflow-hidden"
      >
         
        <BackNavBar />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-12 h-12 m-auto mt-32 flex animate-spin stroke-primary"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
          />
        </svg>
      </div>
    </div>
  );
}
