import React, { useEffect, useState } from "react";
import { AnswerMCQ } from "../AnswerMCQ";
import { ResizeImage } from "../resizeImage";
 
export default function TheCorrectAnswer({ data }) {
 
 {localStorage.getItem("1")=="1"&&console.log(data)}
 
  return (
    <div className="  border-green-600 border-t-4  flex flex-col   ">
   
   

 
       { data?.solution_method ? 
        <div className="  flex flex-col   ">
          <div className="m-auto ">
            <h1 className="border-b-4 p-3 ">مفتاح الحل</h1>
          </div>
          <ResizeImage size="rem" type="answer" text={data?.solution_method} />
        </div>:""
      }



      { data?.model_answer ? (
        <div className=" border-t-4 flex flex-col   ">
          <div className="m-auto p-0 ">
            <h1 className="border-b-4 p-3 ">نموزج الحل </h1>
          </div>
          <ResizeImage size="rem" type="answer" text={data?.model_answer} />
        </div>
      ):""}
    </div>
  );
}
