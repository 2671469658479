import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowTool, showScreenModal } from "../../store/actions";
import axios from "axios";

const ReviewModal = ({ MyImg, quid }) => {
  //  
  //  
  //  
  //  
  //   const { screenModalValue } = useSelector((state) => state.userAppReducer);
  const [loading, setLoading] = useState(false);
  const [screenModalValue, setscreenModalValue] = useState(false);
  const [error, setError] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {

  }, [MyImg, loading, screenModalValue])



  // const { questionsList, currentQues } = useSelector(
  //   (state) => state.userAppReducer
  // );
  // useEffect(() => {
  //    
  //    
  //    
  //    
  //    
  //    
  //    
  //    
  // }, [currentQues]);




  const sendScreen = () => {
    const token = localStorage.getItem("token");
    setLoading(true);
    axios
      .post(
        "https://emmobank.com/api/add_issue",
        {
          question_id: quid,
          image: MyImg,
        },
        {
          headers: {
            "access-token": "qwertasdfg123@+1",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
         
         
         
        setLoading(false);
        //  
        dispatch(showScreenModal(false));
        dispatch(setShowTool(false));
         

      })
      .catch((error) => {
        console.log(error);
        setError("error |: we cant send SCreen shot");
      });
  };

  return (
    <>
      <button onClick={() => { setscreenModalValue(!screenModalValue) }} className=" border p-2 m-1 bg-white " >عرض الصورة السابقة </button>

      {screenModalValue ? (

        <div className="z-[60] h-screen  w-full    fixed top-0 left-0 flex flex-col justify-center items-center">

          <div className="-z-[5] h-screen  w-full bg-gray-300   opacity-80  fixed top-0 left-0 flex flex-col justify-center items-center">

          </div>




          <div className="w-[85%] max-w-[600px] border-2 border-stone-300 shadow-inner	drop-shadow-2xl  max-h-3/4 h-fit bg-white  p-4 rounded-xl flex flex-col gap-3">
            <div className="flex flex-row justify-between w-full pt-6">
              <div className="  w-full text-center   font-bold ">
                Screen shoot is done
              </div>
            </div>

      {console.log(MyImg)}

            <div className="flex flex-col items-center  pt-2 justify-between w-full gap-4">
              <img className="max-h-96 h-fit w-auto m-auto" src={MyImg} alt="صورة" />


              {error && <div className="bg-red-300 p-2 rounded-lg"> error</div>}

              <div className="flex flex-row justify-center flex-wrap w-full gap-7">
                <div
                  className="px-7 rounded-full py-1  text-blue-950 text-center font-[Almarai] font-bold bg-gray-400"
                  onClick={() => setscreenModalValue(false)}
                >
                  Close
                </div>
                {/* <div
                    className="px-7 rounded-full py-1   text-white  text-center font-[Almarai] font-bold  bg-blue-950"
                    onClick={sendScreen}
                  >
                    {loading ? "loading...." : "Send"}
                  </div> */}
              </div>
            </div>
          </div>



        </div>

      ) : null}
    </>
  );
};

export default ReviewModal;
