import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowTool, showScreenModal } from "../../store/actions";
import axios from "axios";

const ModalScreen = ({ MyImg ,quid}) => {
  //  
  //  
  //  
  //  
  const { screenModalValue } = useSelector((state) => state.userAppReducer);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {

  }, [MyImg,loading,screenModalValue])
  





  const sendScreen = () => {
    const token = localStorage.getItem("token");
    setLoading(true);
    axios
      .post(
        "https://emmobank.com/api/add_issue",
        {
          question_id: quid,
          image: MyImg,
        },
        {
          headers: {
            "access-token": "qwertasdfg123@+1",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        //  
        //  
        //  
        setLoading(false);
         
        dispatch(showScreenModal(false));
        dispatch(setShowTool(false));
        //  
        
      })
      .catch((error) => {
        //  
        setError("error |: we cant send SCreen shot");
      });
  };

  useEffect(() => {
    setError('')
  }, [])
  
  return (
    <>
      {screenModalValue ? (
        <>
          <div className="z-[60] h-screen w-screen bg-gray-100 	  absolute top-0 left-0 flex flex-col justify-center items-center">
            <div className="w-[85%] max-w-[600px] max-h-3/4 h-fit bg-white   p-4 rounded-xl flex flex-col gap-3">
              <div className="flex flex-row justify-between w-full pt-6">
                <div className="  w-full text-center   font-bold ">
                  Screen shoot is done
                </div>
              </div>

              <div className="flex flex-col items-center  pt-2 justify-between w-full gap-4">
                   <img className="max-h-96 h-fit w-auto m-auto" src={MyImg} alt="صورة" />
                 
                  
                  {error &&<div className="bg-red-300 p-2 rounded-lg"> error</div>}
                
                <div className="flex flex-row justify-center flex-wrap w-full gap-7">
                  <div
                    className="px-7 rounded-full py-1  text-blue-950 text-center font-[Almarai] font-bold bg-gray-400"
                    onClick={() => dispatch(showScreenModal(false))}
                  >
                    Cancel
                  </div>
                  <div
                    className="px-7 rounded-full py-1   text-white  text-center font-[Almarai] font-bold  bg-blue-950"
                    onClick={sendScreen}
                  >
                    {loading ? "loading...." : "Send"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default ModalScreen;
