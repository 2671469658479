//Pagination
import React from 'react';
import { useSelector,useDispatch} from "react-redux";
import {setCurrentQues} from '../store/actions';
import config from "../sql/config.json";
import $ from 'jquery'

export const Controllers = (props) => {
    const dispatch = useDispatch();
    // @ts-ignore
    const { currentQues, examCounter } = useSelector(state => state.userAppReducer);

    const backQues = () => {
        if($('#paragraphBody')){
            $('#paragraphBody').scrollTop(0,0)
        }
        // @ts-ignore
        dispatch(setCurrentQues((currentQues>=0)?(currentQues-1):0))
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
    }
    const nextQues = () => {
        // @ts-ignore
        if($('#paragraphBody')){
            $('#paragraphBody').scrollTop(0,0)
        }
        dispatch(setCurrentQues((currentQues==(examCounter-1))?(examCounter-1):currentQues+1))
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
    }

    return(
        <div className="w-full flex flex-row justify-between px-1 sm:px-2">
            <button className={`select-none gap-2 flex flex-row  bg-white text-[#001670] text-center py-1 rounded-full ${(currentQues ==0)?'opacity-25':''} items-center`} disabled={(currentQues ==0 )?true:false} onClick={()=>backQues()}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 rtl:rotate-180">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                </svg>
                <span className=" font-['roman'] rtl:font-['Droid']">{config.config[props.lang].prev}</span>
            </button>
            <button className={`select-none gap-2 flex flex-row bg-white text-[#001670] text-center py-1 rounded-full ${(currentQues == (examCounter-1) )?'opacity-25':''} items-center`} disabled={(currentQues == (examCounter-1) )?true:false} onClick={()=>nextQues()}>
                <span className=" font-['roman'] rtl:font-['Droid']">{config.config[props.lang].next}</span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 rtl:rotate-180">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>
            </button>
        </div>
    ) ;

}