import { Navigate, Outlet } from "react-router"

export const ProtectedLayout =()=>{
    // console.log("ProtectedLayout is run ");
    const token =localStorage.getItem("token")
    localStorage.setItem("beforeLogin",window.location.pathname)
    // console.log(token)
    if(!token|| token =="null"){
      return  <Navigate to="/login"  />
    }

return <Outlet/>;}