/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  BackNavBar,
  StartExam,
  Modal,
  Pagination,
  Controllers,
  MCQ,
  Essay,
  Paragraph,
  FullReport,
  EssayModal,
  Timer,
  EssayEvaluation,
  Login,
  Blanks,
  AccessibleScreen,
  Title,
  ResizeImage,
} from "../components";
import {
  setBlur,
  setModal,
  setExamCounter,
  setBriefReport,
  setModalBody,
  setModalTitle,
  setModalBotton,
  setTimer,
  setExamConfig,
  setStartExam,
  setModalSubTitle,
  setUserAnswer,
  setBookmark,
  setQuestionsList,
  setCurrentQues,
  setFullReport,
  setAct,
  setActualNumber,
} from "../store/actions";
import config from "../sql/config.json";
import { v4 as uuidv4 } from "uuid";
// import { useIsAuthenticated } from "react-auth-kit";
import axios from "axios";
import CanvasScreen from "../canvasScreen/CanvasScreen";
import NoQuestions from "../components/examCompo/NoQuestions";
import Loading from "../components/examCompo/Loading";
import TheCorrectAnswer from "../components/ReviewCompo/TheCorrectAnswer";

export const Exam = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  // const exams = require("../data/exams/" + params.id + ".json");
  // const isAuthenticated = useIsAuthenticated();

  const dispatch = useDispatch();
  const [exams, setExams] = useState([]);
  const {
    lang,
    currentQues,
    startExam,
    blur,
    modal,
    examCounter,
    briefReport,
    fullReport,
    userAnswer,
    actualNumber,
    essayModal,
    examConfig,
  } = useSelector((state) => state.userAppReducer);
  const [questions, setQuestions] = useState(exams);
  const [isLoading, setIsLoading] = useState(true);
  const [reportMode, setReportMode] = useState(window.location.search);
  const [fullScoreMain, setFullScoreMain] = useState(0);
  const [fullUserScore, setFullUserScore] = useState(0);
  const [userIfAnswer, setUserIfAnswer] = useState(false);
  const [modelEssayEvaluation, setModelEssayEvaluation] = useState(false);
  // const [activeLogin, setActiveLogin] = useState(
  //   isAuthenticated() === false &&
  //     localStorage.getItem("userLogin") &&
  //     localStorage.getItem("userLogin") === false
  //     ? true
  //     : false
  // );
  const [accessable, setAccessable] = useState(true);
  const [images, setImages] = useState([]);
  const [currentDivision, setCurrentDivision] = useState(
    localStorage.getItem("division")
  );
  const [loading, setLoading] = useState(true);

  const [subject, setSubject] = useState();

  const mytoken = localStorage.getItem("token");
  const headers = {
    "access-token": "qwertasdfg123@+1",
    Authorization: `Bearer ${mytoken}`,
    // Authorization  : `Bearer ${mytoken}`
  };
  useEffect(() => {
    getExam();
  }, [userIfAnswer]);
  const getExam = async () => {
    // /data/exams/" + params.id + ".json
    await axios
      .get(
        `https://emmobank.com/api/get_exam?exam_id=${params.id}&subject_id=${params.subjectID}&old_width=${window.innerWidth}&new_width=${window.innerWidth}`,
        {
          headers: headers,
        }
      )
      .then(async (respons) => {
          
        setExams(respons.data.exam);
        setQuestions(respons.data.exam);
        return respons.data.exam;
      })
      .then((e) => {
        setTimeout(() => {
          setLoading(false);
          dispatch(setBlur(true));
          dispatch(setBriefReport(false));
          dispatch(setFullReport(false));
          dispatch(setCurrentQues(0));
          dispatch(setStartExam(false));
          dispatch(setModal(false));
          dispatch(setQuestionsList(e.questions));
          dispatch(
            setUserAnswer(
              localStorage.getItem("userAnswer_" + params.id) !== null
                ? JSON.parse(localStorage.getItem("userAnswer_" + params.id))
                : []
            )
          );
          dispatch(
            setTimer(
              localStorage.getItem("userTime_" + params.id) !== null
                ? localStorage.getItem("userTime_" + params.id) - e.duration
                : e.duration
            )
          );

          // @ts-ignore
          window.bookmark = [];
          // @ts-ignore
          window.report = [];
          // @ts-ignore
          window.time = e.duration;
          let a = localStorage.getItem("userTime_" + params.id);
          let b = e.duration;
          if (a > b) {
            localStorage.setItem("userTime_" + params.id, 0);
          }
          // @ts-ignore
          // window.userTime = (localStorage.getItem("userAnswer_" + params.id)) ? parseInt(localStorage.getItem("userTime_" + params.id)) : 0;
          setUserIfAnswer(
            localStorage.getItem("userAnswer_" + params.id)
              ? localStorage.getItem("userAnswer_" + params.id).length
              : 0
          );
          startTimerMain();
          // @ts-ignore
          dispatch(setExamCounter(e.questions.length));
          if (
            userAnswer.length <= 0 ||
            briefReport == true ||
            fullReport == true
          ) {
            // @ts-ignore
            dispatch(setModal(false));
            dispatch(setStartExam(true));
            dispatch(setBriefReport(false));
            dispatch(setFullReport(false));
          } else {
            // @ts-ignore
            dispatch(setStartExam(false));
            // @ts-ignore
            dispatch(setModalBotton([...buttonsListForModelFinish]));
            // @ts-ignore
            dispatch(setModalTitle(""));
            // @ts-ignore
            dispatch(
              setModalSubTitle(
                // config.config[exams?.meta_data?.language   =="ar"?"ar"?"en"].takenBody
                config.config["ar"].takenBody
              )
            );
            // @ts-ignore
            dispatch(setModalBody(""));
            // @ts-ignore
            dispatch(setBlur(true));
            // @ts-ignore
            dispatch(setModal(true));
          }
          // @ts-ignore
          dispatch(setExamConfig(questions));
          setTimeout(() => {
            // @ts-ignore
            setIsLoading(document.readyState === "complete" ? 0 : 1);
          }, 100);
          if (params.index) {
            dispatch(setModal(false));
            dispatch(setStartExam(false));
            dispatch(setBriefReport(false));
            dispatch(setBlur(false));
            dispatch(setCurrentQues(parseInt(params.index) - 1));
          }
        }, 10);
      })
      .catch(() => {})
      .finally(() => {});
  };

  const startTimeFrom = () => {
    window.timer = true;
  };

  const closeModel = () => {
    // @ts-ignore
    dispatch(setBlur(false));
    // @ts-ignore
    dispatch(setModal(false));
    if (exams.timer === true) {
      startTimeFrom();
    }
  };

  const buttonsListForModelFinish = [
    {
      text: config.config[exams?.meta_data?.language==="ar"?"ar":"en"].complete,
      color: "text-white", // tail wind css color
      border: "border", //tail wind css border
      borderColor: "border-primary", //tail wind css border
      background: "bg-primary",
      action: closeModel,
    },
    {
      text: config.config[exams?.meta_data?.language==="ar"?"ar":"en"].Retake,
      color: "text-[#151048]", // tail wind css color
      border: "border", //tail wind css border
      borderColor: "border-gray-300", //tail wind css border
      background: "bg-gray-300",
      action: () => {
        localStorage.removeItem("userTime_" + params.id);
        localStorage.removeItem("userAnswer_" + params.id);
        window.location.reload(false);
      },
    },
  ];

  const toggleModel = () => {
    // @ts-ignore
    dispatch(setBlur(!blur));
    // @ts-ignore
    dispatch(setModal(!modal));
  };

  const CloseModel = () => {
    // @ts-ignore
    dispatch(setBlur(false));
    // @ts-ignore
    dispatch(setModal(false));
  };

  const ViewBriefReport = () => {
    dispatch(setBlur(false));
    // @ts-ignore
    dispatch(setModal(false));
    // @ts-ignore
    setModelEssayEvaluation(false);
    dispatch(setBriefReport(true));

    // @ts-ignore
    window.timer = false;
  };

  const finishExam = () => {
    // @ts-ignore
    dispatch(
      setModalBotton([
        {
          text: config.config[questions?.meta_data.language=="ar"?"ar":"en"].yes,
          color: "text-white", // tail wind css color
          border: "border", //tail wind css border
          borderColor: "border-primary", //tail wind css border
          background: "bg-primary",
          action: ViewBriefReport,
        },
        {
          text: config.config[questions?.meta_data.language=="ar"?"ar":"en"].no,
          color: "text-[#151048]", // tail wind css color
          border: "border", //tail wind css border
          borderColor: "border-gray-500", //tail wind css border
          background: "bg-gray-200",
          action: CloseModel,
        },
      ])
    );

    // @ts-ignore
    dispatch(setModalTitle(questions.title));
    // @ts-ignore
    dispatch(
      setModalSubTitle(
        config.config[questions?.meta_data.language=="ar"?"ar":"en"].ConfirmFinishExam
      )
    );
    dispatch(setModalBody(""));
    toggleModel();
  };


  const userRight = () => {
    var num = 0;
    userAnswer.map((e) => {
      if (e && e.type) {
        num = exams.marked === true ? num + e.userScore : num +e.userScore ;
        //   
      } else {
        if (e) {
          let help = [];
          let totalAns = 0;
          let quesScore = 0;
          e.map((q) => {
            //   
            if (q != null) {
              if (q.type === "blanks") {
                // let qu = e.filter((s)=>s.userScore==0)
                //   
                help.push(q.userScore);
                totalAns = q.totalAns;
                quesScore = q.score;
              } else {
                if (q[0] != null) {
                  num =
                    exams.marked === true
                      ? num + q[0].userScore
                      : num + q[0].score;
                }
              }
            }
          });

          if (!help.includes(0) && help.length === totalAns) num += quesScore;
        }
      }
      //   
    });
    return num;
  };



  function startTimerMain() {
    const duration = exams.duration;
    const pad = (n) => (n < 10 ? `0${n}` : n);
    const h = Math.floor(duration / 3600);
    const m = Math.floor(duration / 60) - h * 60;
    const s = Math.floor(duration - h * 3600 - m * 60);
    // @ts-ignore
    window.time = h + ":" + pad(m) + ":" + pad(s);

    // @ts-ignore
    dispatch(setTimer(h + ":" + pad(m) + ":" + pad(s)));
  }
  const gotoDivision = () => {
    navigate("/home");
  };

  //   
  //   

  if (exams?.questions?.length === 0) {
    return (
      <>
        <NoQuestions />
      </>
    );
  }

  if (questions?.questions && !loading) {
    return (
      <CanvasScreen language={questions?.meta_data.language}>
        <div
          dir={questions?.meta_data.language === "ar" ? "rtl" : "ltr"}
          className={`w-full absolute z-10 h-screen p-0 bg-gray-100 flex flex-col font-[Almarai] md:m-auto `}
        >
         
          <div id="loadImagesToLocal" className="hidden"></div>
          {accessable === true ? (
            true ? (
              <>
                {/* <BackNavBar  /> */}

                {fullReport === false ? <Timer active={exams.timer} /> : <></>}

                <div
                  dir={questions?.meta_data.language === "ar" ? "rtl" : "ltr"}
                  className={`bg-white w-full h-full
                 overflow-auto`}
                >
                  <div
                    className={`min-h-full flex-col flex justify-between px-2 sm:px-10 pb-5`}
                  >
                    {questions?.questions?.[currentQues]?.QuesType ===
                    "Paragraph" ? (
                      <div className="w-full h-full py-4 flex flex-col">
                        <div className="w-full flex flex-col justify-start gap-4 relative">
                          {questions?.questions?.[currentQues].QuesHeadTxt !=
                          "" ? (
                            <Title
                              quesNumber={currentQues + 1}
                              question={questions?.questions?.[currentQues]}
                              bookmark={false}
                            />
                          ) : (
                            <></>
                          )}

                          <div
                            className={`h-fit max-h-[27vh] overflow-auto font-['roman'] rtl:font-['Droid'] border rounded-t-md rounded-b-[20px] border-gray-300 p-2 bg-white`}
                          >
                            <ResizeImage
                              size="rem"
                              type="paragraph"
                              text={
                                questions?.questions?.[currentQues]?.QuesTxt
                              }
                            />
                          </div>
                          <div
                            id="paragraphBody"
                            className={`h-fit max-h-[50vh] overflow-auto border-t`}
                          >
                            <Paragraph
                              essayKey={uuidv4()}
                              index={
                                questions?.questions?.[currentQues]?.QuesID
                              }
                              lang={questions?.meta_data.language}
                              key={currentQues}
                              quesNumber={currentQues + 1}
                              question={questions?.questions?.[currentQues]}
                            />
                            <div className="flex flex-col h-fit justify-center">
                              <Controllers
                                lang={questions?.meta_data.language}
                              />
                              <Pagination
                                number={examCounter}
                                type={exams?.questions}
                              />
                              {fullReport !== true ? (
                                <button
                                  onClick={() => finishExam()}
                                  className="w-full sm:w-52 m-auto border-primary text-white bg-primary border text-center py-2 px-4 rounded-full  font-['roman'] rtl:font-['Droid']"
                                >
                                  {
                                    config.config[
                                      questions?.meta_data.language=="ar"?"ar":"en"
                                    ].finishExam
                                  }
                                </button>
                              ) : (
                                <button
                                  onClick={() => dispatch(setBriefReport(true))}
                                  className="w-full sm:w-52 m-auto border-primary text-white bg-primary border text-center py-2 px-4 rounded-full  font-['roman'] rtl:font-['Droid']"
                                >
                                  {
                                    config.config[
                                      questions?.meta_data.language=="ar"?"ar":"en"
                                    ].briefRprtBtn
                                  }
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="w-full pb-4 px-1 flex flex-col">
                        <div>
                          {questions?.questions?.[currentQues]?.QuesType ===
                          "mcq" ? (
                            <MCQ
                              id={params.id}
                              lang={questions?.meta_data.language=="ar"?"ar":"en"}
                              key={currentQues}
                              quesNumber={currentQues + 1}
                              question={questions?.questions?.[currentQues]}
                              answers={
                                questions?.questions?.[currentQues]?.QuesAns
                              }
                            />
                          ) : questions?.questions?.[currentQues]?.QuesType ===
                            "blanks" ? (
                            <Blanks
                              lang={questions?.meta_data.language=="ar"?"ar":"en"}
                              key={currentQues}
                              quesNumber={currentQues + 1}
                              question={questions?.questions?.[currentQues]}
                              answers={
                                questions?.questions?.[currentQues]?.QuesAns
                              }
                            />
                          ) : (
                            <Essay
                              lang={questions?.meta_data.language=="ar"?"ar":"en"}
                              key={currentQues}
                              quesNumber={currentQues + 1}
                              question={questions?.questions?.[currentQues]}
                              answers={
                                questions?.questions?.[currentQues]?.QuesAns
                              }
                            />
                          )}
                        </div>
                      </div>
                    )}
                    {questions?.questions?.[currentQues]?.QuesType !==
                    "Paragraph" ? (
                      <div className="flex flex-col h-fit justify-center">
                                            <TheCorrectAnswer data={questions?.questions?.[currentQues]} />

                        <Controllers
                          lang={questions?.meta_data.language=="ar"?"ar":"en"}
                        />
                        <Pagination
                          number={examCounter}
                          type={exams?.questions}
                        />
                        {fullReport !== true ? (
                          <button
                            onClick={() => finishExam()}
                            className="w-full sm:w-52 m-auto border-primary text-white bg-primary border text-center py-2 px-4 rounded-full  font-['roman'] rtl:font-['Droid']"
                          >
                            {
                              config.config[
                                questions?.meta_data.language=="ar"?"ar":"en"
                              ].finishExam
                            }
                          </button>
                        ) : (
                          <button
                            onClick={() => dispatch(setBriefReport(true))}
                            className="w-full sm:w-52 m-auto border-primary text-white bg-primary border text-center py-2 px-4 rounded-full  font-['roman'] rtl:font-['Droid']"
                          >
                            {
                              config.config[
                                questions?.meta_data.language=="ar"?"ar":"en"
                              ].briefRprtBtn
                            }
                          </button>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {/* <EssayEvaluation
                        active={
                          modelEssayEvaluation === true && fullReport === false
                        }
                        lang={exams?.meta_data.language||"ar"}
                      /> */}
                       
                    <FullReport
                      action={() => {}}
                      retray={true}
                      lang={exams?.meta_data.language=="ar"?"ar":"en"}
                      active={briefReport}
                      message={
                        config.config[exams?.meta_data.language=="ar"?"ar":"en"]
                          .ExcelentRsltMsg
                      }
                      total={actualNumber}
                      right={{
                        number: userRight(),
                        color: ["#C0E7BA", "#2FAE1D"],
                      }}
                      exam={exams}
                    />
                    <StartExam
                      active={startExam}
                      action=""
                      lang={questions?.meta_data.language=="ar"?"ar":"en"}
                      timer={{
                        active: questions?.timer,
                        time: questions?.duration,
                      }}
                      number=""
                      title={questions?.title}
                      exam={exams}
                    />
                    {/* finish Model */}
                    <Modal
                      active={modal}
                      lang={exams?.meta_data.language=="ar"?"ar":"en"}
                    />
                    <EssayModal
                      active={essayModal}
                      lang={exams?.meta_data.language=="ar"?"ar":"en"}
                      exam={exams}
                    />
                    <div className="h-10" />
                  </div>
                </div>
              </>
            ) : (
              ""
            )
          ) : (
            <AccessibleScreen
              active={true}
              lang={lang}
              action={() => gotoDivision()}
            />
          )}
        </div>
      </CanvasScreen>
    );
  } else {
    return (
      <>
        <Loading />
      </>
    );
  }
};
