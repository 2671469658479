import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { ResizeImage } from './resizeImage';
import $ from 'jquery'

export const Modal = (props) => {
    const { modalTitle, modalBody, modalBotton, modalSubTitle, } = useSelector(state => state.userAppReducer);
    const [imageLoaded, setiImageLoaded] = useState(true)

    useEffect(() => {
        window.imagesCount = 0
        checkImageCount()
    }, [modalBotton])

    const checkImageCount = () => {
        $('#checkImageCount').html(modalBody)
        var img = $('img'); // استخراج جميع الصور في الصفحة
        var images = $('#checkImageCount').find('img')
        if (images.length > 0) {
            img.on('load', function () {
                if (this.complete && typeof this.naturalWidth !== "undefined" && this.naturalWidth > 0) {
                    window.imagesCount = window.imagesCount + 1
                    if (window.imagesCount >= images.length || images.length == 0) {
                        setTimeout(() => {
                            setiImageLoaded(false)
                        }, 500);
                    }
                }
            })
        } else {
            setiImageLoaded(false)
        }
    }
    
    if (props.active == true) {
        return (
            <div className="w-screen h-screen bg-[#001670] bg-opacity-25 right-0 left-0  flex justify-center items-center align-middle content-center self-center z-50  md:max-w-[875px]  fixed top-0 md:m-auto overflow-hidden">
                <div id="checkImageCount" className='hidden'></div>
                <div className="bg-white max-h-[95vh] overflow-hidden rounded-3xl w-11/12 max-w-[500px] h-fit flex flex-col  gap-4 justify-start">
                    {
                        (imageLoaded == true) ? (
                            <div className='bg-white absolute w-full h-full p-0 m-0 -ml-2 -mt-2 rounded-xl z-40 opacity-90 flex justify-center items-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 animate-spin stroke-primary">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg>
                            </div>
                        ) : ''
                    }
                    <div className='w-full h-full overflow-y-scroll overflow-hidden px-2 py-4 flex flex-col  gap-4 justify-start'>
                        <h1 className={`w-full shrink-0 flex justify-center font-semibold text-center text-xl ${(props.fonts)?props.fonts:''}`}>{modalTitle}</h1>
                        <div className={`font-[roman] rtl:font-[Droid] text-xl leading-[40px] sm:leading-[50px] text-center w-full`}>
                            {modalSubTitle}
                        </div>
                        <div className='flex h-full text-left rtl:text-right'>
                            <ResizeImage size="rem" type="modelAnswer" fonts={props.fonts} text={modalBody} />
                        </div>
                        <div className="shrink-0 h-10 flex flex-row justify-between gap-5">
                            {
                                modalBotton.map((button, index) => (
                                    <button key={index} type='button' onClick={button.action} className={`${button.color + ` ` + button.border + ` ` + button.borderColor + ` ` + button.background} select-none text-center py-2 ${(modalBotton.length <= 1) ? 'w-1/2' : 'w-full'} rounded-full m-auto`}>{button.text}</button>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return ""
    }

}