import React from 'react';
import { useSelector } from "react-redux";
import { BackNavBar } from '../components';
import langConfig from "../configuration/lang.json"
import { Flip, toast } from 'react-toastify';

export const NotFound = (props) => {
    const { lang } = useSelector(state => state.userAppReducer);









   
    return (
        <div dir={(lang === "ar") ? 'rtl' : 'ltr'} className="flex flex-col justify-center h-full w-screen p-0 m-0 md:max-w-[875px] m-auto overflow-hidden">
          <div className='m-auto'>
 
          </div>
            <div className="flex flex-col gap-3 w-full h-fit px-4 py-3">
            <BackNavBar canBack={true} />
                <div className="full w-d flex justify-center items-center min-h-[90vh] flex-col gap-9">
                    <img src="/bg/notFound.svg" className="w-3/4" alt={langConfig[lang].notFound} />
                    <p>{langConfig[lang].notFound}</p>
                </div>
            </div>
        </div>
    )

}